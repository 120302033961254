import { configureStore } from "@reduxjs/toolkit";

import { customerSlice } from "./slices/customersSlice";
import { miscSlice } from "./slices/miscSlice";
import { ordersSlice } from "./slices/ordersSlice";
import { citiesSlice } from "./slices/citiesSlice";
import { linesSlice } from "./slices/linesSlice";
import { usersSlice } from "./slices/usersSlice";
import { processSlice } from "./slices/processSlice";
import { permissionsSlice } from "./slices/permissionsSlice";
import { listsSlice } from "./slices/listsSlice";

export const store: any = configureStore({
    reducer: {
        cities : citiesSlice.reducer,
        customer : customerSlice.reducer,
        lines : linesSlice.reducer,
        misc : miscSlice.reducer,
        orders: ordersSlice.reducer,
        users : usersSlice.reducer,
        process : processSlice.reducer,
        permissions : permissionsSlice.reducer,
        lists : listsSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;