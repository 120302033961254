import { memo } from 'react';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSignOutAlt, 
    faEye, 
    faEyeSlash, 
    faUsersViewfinder, 
    faUsersCog,
    faUserPlus,
    faChartBar,
    faCog,
    faFlagCheckered,
    faCogs,
    faTrash,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';


import logo from '../assets/images/logo-horizontal.png';
import logosimbolo from '../assets/images/logo-simbolo.png';


import { useGroups, usePermissions } from '../services';

const Sidebar : React.FC = () => {

    const { canView_orders, canView_process, canView_user } = usePermissions(['view_orders', 'view_process','view_user']);
    const { 
        isAdministrador, 
        isJefedeVentas, 
        isValidador, 
        isJefedeProduccion, 
        isVendedor 
    } = useGroups([
        'Administrador', 
        'Jefe de Ventas', 
        'Validador', 
        'Jefe de Produccion', 
        'Vendedor'
    ]);

    const canView_stats = isAdministrador || isJefedeVentas || isJefedeProduccion;

    const { logout, setOpen, open } = useAppContext();
    const navigate = useNavigate();

    const handleNavigate = (path:string) => {
        navigate(path);
        setOpen(false);
    }

    const handleLogout = () => {
        logout();
        navigate('/');
    }

    const ableToViewProcess = !(isValidador || isVendedor) && canView_process;
    const ableToViewUnvalidate = isValidador || isAdministrador ;
    const ableToViewCreated = !isValidador && canView_orders;
    
    return  <SidebarContainer className={open ? 'open' : 'close'}>
        <HiddenButton  className ={open? 'open' : 'close'} onClick={() => setOpen(!open)}>
           <img src={logosimbolo} className ={open? 'open' : 'close'} alt="logo" /> <FontAwesomeIcon icon={open ? faEyeSlash :faEye} />
        </HiddenButton>
    {open? <>
        <div className="sidebar-header">
      <img src={logo} alt="logo" />
    </div>
    <div className="sidebar-body">
        {ableToViewCreated ? <SidebarItem onClick={() => handleNavigate('ordenes/')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faUsersViewfinder} />
            </div>
            <div className="sidebar-item-text">
                Órdenes Creadas
            </div>
        </SidebarItem>  : null}
        {ableToViewUnvalidate ? <SidebarItem onClick={() => handleNavigate('ordenes/por-validar')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            <div className="sidebar-item-text">
                Órdenes Por Validar
            </div>
            </SidebarItem>  : null}
        {
            canView_orders ? <SidebarItem onClick={() => handleNavigate('ordenes/proceso')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faCogs} />
            </div>
            <div className="sidebar-item-text">
                Órdenes en Proceso
            </div>
        </SidebarItem> : null
        }
        {canView_orders ? <SidebarItem onClick={() => handleNavigate('ordenes/finalizadas')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faFlagCheckered} />
            </div>
            <div className="sidebar-item-text">
                Órdenes Finalizadas
            </div>
        </SidebarItem>  : null}
        {isAdministrador ? <SidebarItem onClick={() => handleNavigate('ordenes/eliminadas')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faTrash} />
            </div>
            <div className="sidebar-item-text">
                Órdenes Eliminadas
            </div>
        </SidebarItem>  : null}
        {ableToViewProcess ? <SidebarItem onClick={() => handleNavigate('procesos/')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faUsersCog} />
            </div>
            <div className="sidebar-item-text">
                Procesos
            </div>
        </SidebarItem>  : null}
        { canView_user ? <SidebarItem onClick={() => handleNavigate('usuarios/')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faUserPlus} />
            </div>
            <div className="sidebar-item-text">
                Usuarios
            </div>
        </SidebarItem> : null}
        {
            canView_stats ? <SidebarItem onClick={() => handleNavigate('estadisticas/')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faChartBar} />
            </div>
            <div className="sidebar-item-text">
                Estadísticas
            </div>
        </SidebarItem> : null
        }
    </div>
    {isAdministrador || isJefedeVentas ?<div className="sidebar-footer">
        <SidebarItem onClick={() => handleNavigate('configuraciones/')}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faCog} />
            </div>
            <div className="sidebar-item-text">
                Configuraciones
            </div>
        </SidebarItem>
    </div> : null}
    <div className="sidebar-footer">
        <SidebarItem onClick={handleLogout}>
            <div className="sidebar-item-icon">
                <FontAwesomeIcon icon={faSignOutAlt} />
            </div>
            <div className="sidebar-item-text">
                Cerrar sesión
            </div>
        </SidebarItem>
    </div>
    </> : null}
    </SidebarContainer>;
};

const SidebarContainer = styled.div`
    width: 200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--blueLight);
    border-right: 1px solid #f1f1f1;
    transition: all 0.3s ease;
    position: fixed;
    left:0;
    z-index: 999;
    .sidebar-header {
        padding: 10px;
        border-bottom: 1px solid #f1f1f1;
        img {
            width: 100%;
        }
    }
    .sidebar-body {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;
    }
    .sidebar-footer {
        padding: 10px;
        border-top: 1px solid #f1f1f1;
    }
    &.open {
        left:0;
    }
    &.close {
        left:-200px;
    }
`;


const SidebarItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        color: var(--blueDark);
    }
    .sidebar-item-icon {
        margin-right: 10px;
    }
    .sidebar-item-text {
        font-size: 14px;
        font-weight: 500;
    }
`;

const HiddenButton = styled.div`
    position: absolute;
    background: #0b4a87;
    display: flex;
    left: 200px;
    border-radius: 0px 25px 25px 0px;
    height: 40px;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 3px #010101;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1000;
    &:hover {
        background-color: var(--blueDark);
    }
    &.open {
        right: 210px;
        width: 40px;
    }
    &.close {
        right: 10px;
        width: 80px;
    }
    img {
        width: 30px;
        margin-right: 5px;
    }
    img.open{
        display: none;
    }
    img.close{
        display: block;
    }
`;

export default memo(Sidebar);