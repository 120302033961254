import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import { usePermissions, useProcessPermissions } from '../../services';
import { useEffect } from 'react';

// hooks redux
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setProcesses, setProcess } from '../../slices/processSlice';

// context 
import { useAppContext } from '../../Context';
import { LoaderTemporal } from '../../components/shared/Loader';
import { ProcessType } from '../../types';

import IconAwesome from '../../components/shared/IconAwesome';

const ProcessItem : React.FC<{process: ProcessType, index: number}> = ({process, index}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id, process_name, process_description, process_icon, process_slug_permission } = process;

    const canViewThis = useProcessPermissions(`view_${process_slug_permission}`);

    const handleClick = () => {
        dispatch(setProcess(process));
        navigate(`/proceso/${id}`);
    }

    if (!canViewThis) return null;

    return <div className="process-card" key={index} onClick={handleClick}>
        <div className="process-card-body">
            {process_icon  ? <div className="process-card-icon"><IconAwesome processIcon={process_icon} /></div> : null}
            <h5 className="process-card-title">{process_name}</h5>
            <p className="process-card-text">{process_description}</p>
        </div>
    </div>
}


const ViewProcesses : React.FC = () => {

    const { canView_process } = usePermissions(['view_process']);
 
    const { apiCall } = useAppContext();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { processes } = useAppSelector(state => state.process);

    useEffect(() => {
        if (!canView_process) {
            navigate('/');    
            return
        }

        const getProcesses = async () => {
            const response = await apiCall('GET', 'processes/', null , '', 'No se pudo obtener los procesos', true);
            if (response) {
                dispatch(setProcesses(response));
            }
        }

        //if (processes.length === 0) 
        getProcesses();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

    if (processes.length === 0) {
        return <LoaderTemporal />;
    }
   return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className="text-left">
            <h2 className="text-left">Procesos</h2>
        </Grid>
    </Grid> 
    <Grid container spacing={2}>
        <Grid item xs={12} key={"process"} className="text-left">
            <div className="processes-wrap">
                {
                    processes.map((process:ProcessType, index:number) => {
                       return <ProcessItem process={process} index={index} key={index}/>
                    })
                }
            </div>
        </Grid>
    </Grid>
</div>;
}

export default ViewProcesses;