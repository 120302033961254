import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Button } from "../../components/shared/FormElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";

import { useAppDispatch, useAppSelector } from '../../hooks';
import { useAppContext } from '../../Context';
//  style
import '../../styles/DataTable.css';
import { LineType } from "../../types";
import { useEffect, useState } from "react";
import { setLoading } from "../../slices/miscSlice";
import { setLines } from "../../slices/linesSlice";
import { setPopAlert } from "../../slices/miscSlice";

const ViewSettings: React.FC = () => {
    const  { apiCall} = useAppContext();
    const lines  = useAppSelector(state => state.lines.lines);
    const dispatch = useAppDispatch();

    const [addNewForm, setAddNewForm] = useState(false);
    const [newLine, setNewLine] = useState('' as string);
    const [editForm, setEditForm] = useState(false);
    const [editLine, setEditLine] = useState({} as LineType);

    useEffect(() => {

        if (lines.length === 0) {
            const getLines = async () => {
                dispatch(setLoading(true));
                const response = await apiCall('GET', 'lines/', null, '', 'Error al obtener las líneas');
                dispatch(setLoading(false));
                dispatch(setLines(response));
            }
            getLines();
        }
        // eslint-disable-next-line
    }, []);

    const handleSaveLine = async () => {
        if (newLine === '') {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'El nombre de la línea es requerido',
                show: true
            }));
            return;
        }
        dispatch(setLoading(true));
        const data = {
            line_name: newLine
        }
        const response = await apiCall('POST', 'line/', data, 'Línea creada exitosamente', 'Error al crear la línea');
        dispatch(setLoading(false));
        if (response.id) {
            dispatch(setLines([...lines, response]));
            setAddNewForm(false);
            setNewLine('');
        }else{
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al crear la línea',
                show: true
            }));
        }
    }

    const handleEditLine = (line:LineType) => {
        setEditForm(true);
        setEditLine(line);
    }

    const handleUpdateLine = async () => {
        if (editLine.line_name === '') {
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'El nombre de la línea es requerido',
                show: true
            }));
            return;
        }
        dispatch(setLoading(true));
        const data = {
            line_name: editLine.line_name
        }
        const response = await apiCall('PUT', `line/${editLine.id}/`, data, 'Linea actualizada exitosamente', 'Error al actualizar la línea');
        dispatch(setLoading(false));
        if (response?.id) {
            const updatedLines = lines.map((line:LineType) => {
                if (line.id === response.id) {
                    return response;
                }
                return line;
            });
            dispatch(setLines(updatedLines));
            setEditForm(false);
            setEditLine({} as LineType);
        }else{
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al actualizar la línea',
                show: true
            }));
        }
    }

    const handleDeleteLine = async (id:number) => {
        dispatch(setLoading(true));
        const response = await apiCall('DELETE', `line/${id}/delete/`, null, 'Línea eliminada exitosamente', 'Error al eliminar la línea');
        dispatch(setLoading(false));
        if (response) {
            const updatedLines = lines.filter((line:LineType) => line.id !== id);
            dispatch(setLines(updatedLines));
        }
    }

    const handleRefreshClients = async () => {
        dispatch(setLoading(true));
        const response = await apiCall('GET', 'customers/refresh/', null, '', 'Error al obtener los clientes');
        dispatch(setLoading(false));
        if (response) {
            dispatch(setPopAlert({
                alertType: 'success',
                message: 'Clientes actualizados correctamente',
                show: true
            }));
        }else{
            dispatch(setPopAlert({
                alertType: 'error',
                message: 'Error al actualizar los clientes',
                show: true
            }));
        }
    }

    return (
        <div>
        <h1>Configuraciones</h1>
            <div className="container-wrap">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} className="text-left">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={10} className="text-right">
                                <h2>Lineas</h2>
                            </Grid>
                            <Grid item xs={12} sm={2} className="text-right">
                            <Button onClick={() => setAddNewForm(true)}>Agregar</Button>
                            </Grid>
                        </Grid>
                        {addNewForm && <div>
                            <h3>Agregar Nueva Linea</h3>
                            <form>
                                <input type="text" placeholder="Nombre de la linea" value={newLine} onChange={(e) => setNewLine(e.target.value)} />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button onClick={() => setAddNewForm(false)}>Cancelar</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={handleSaveLine}>Guardar</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>}
                        {
                            editForm && <div>
                                <h3>Editar Linea</h3>
                                <form>
                                    <input type="text" placeholder="Nombre de la linea" value={editLine.line_name} onChange={(e) => setEditLine({...editLine, line_name: e.target.value})} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button onClick={() => setEditForm(false)}>Cancelar</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button onClick={handleUpdateLine}>Guardar Cambios</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        }
                        {lines.length === 0 && <p>No hay líneas registradas</p>}
                        <Grid container spacing={2}>
                            <div className="table-responsive">
                           <TableContainer className="table-container">
                                 <Table>
                                      <TableHead>
                                        <TableRow>
                                             <TableCell><b>NOMBRE</b></TableCell>
                                             <TableCell
                                                width={100}
                                             ><b>ACCIONES</b></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                            {lines?.map((line : LineType) => (
                                                <TableRow key={line.id}>
                                                    <TableCell>{line.line_name}</TableCell>
                                                    <TableCell>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <Button onClick={() => handleEditLine(line)}><FontAwesomeIcon icon={faPencil} /></Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button onClick={() => handleDeleteLine(line.id)}><FontAwesomeIcon icon={faTrash} /></Button>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                                ))}
                                      </TableBody>
                                 </Table>
                            </TableContainer>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} className="text-right">
                        <h2>Lista de Clientes</h2>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} className="text-right">

                            </Grid>
                            <Grid item xs={12} sm={6} className="text-center">

                            <Button onClick={handleRefreshClients}><FontAwesomeIcon icon={faRefresh} spin /> Actualizar Clientes desde Siigo</Button>
                            </Grid>
                            <Grid item xs={12} sm={3} className="text-right"></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
    }

export default ViewSettings;

