const Pagination: React.FC<{ 
    pagination: any, 
    paginationNumbers:any, 
    handleChangePage:any}> = ( { pagination, paginationNumbers, handleChangePage } ) => {

        const totalPages = paginationNumbers.length;
        const currentPage = pagination.page;
      
        // Determinar los números de página a mostrar
        let startPage:number, endPage:number;
        if (totalPages <= 10) {
          startPage = 1;
          endPage = totalPages;
        } else {
          if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
          } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
          } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
          }
        }
      
        const pageNumbers = Array.from({ length: (endPage + 1) - startPage }, (_, i) => startPage + i);
      
        return (
          <nav aria-label='Page navigation example'>
            <ul className='pagination'>
              <li className={'page-item ' + (currentPage === 1 ? 'disabled' : '')}>
                <button className='page-link' onClick={() => handleChangePage(null, 1)}>
                  Primera
                </button>
              </li>
              <li className={'page-item ' + (currentPage === 1 ? 'disabled' : '')}>
                <button className='page-link' onClick={() => handleChangePage(null, currentPage - 1)} disabled={currentPage === 1 ? true : false }>
                  Anterior
                </button>
              </li>
      
              {startPage > 1 && (
                <li className='no-button'>
                  <span className='page-link'>...</span>
                </li>
              )}
      
              {pageNumbers.map((number) => (
                <li key={number} className={'page-item ' + (number === currentPage ? 'active' : '')}>
                  <button className='page-link' onClick={() => handleChangePage(null, number)}>
                    {number}
                  </button>
                </li>
              ))}
      
              {endPage < totalPages && (
                <li className='no-button'>
                  <span className='page-link'>...</span>
                </li>
              )}
      
              <li className={'page-item ' + (currentPage === totalPages ? 'disabled' : '')}>
                <button className='page-link' onClick={() => handleChangePage(null, currentPage + 1)} disabled={currentPage === totalPages ? true : false}>
                  Siguiente
                </button>
              </li>
              <li className={'page-item ' + (currentPage === totalPages ? 'disabled' : '')}>
                <button className='page-link' onClick={() => handleChangePage(null, totalPages)}>
                  Última
                </button>
              </li>
            </ul>
          </nav>
        );

};

export default Pagination;