import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface CitiesState {
    cities: City[];
    city : City;
}

export interface City{
    id: number;
    city_name: string;
    state_name?: string;
    country_name?: string;
}

const initialState: CitiesState = {
    cities : [],
    city : {
        id: 0,
        city_name: "",
        state_name: "",
        country_name: "",
    }
}

export const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {
        setCities: (state, action: PayloadAction<City[]>) => {
            state.cities = action.payload;
        },
        setCity: (state, action: PayloadAction<City>) => {
            state.city = action.payload;
        }
    },
})

export const { setCities, setCity } = citiesSlice.actions

export const selectCity = (state: RootState) => state.cities.city
export const selectCities = (state: RootState) => state.cities.cities

export default citiesSlice.reducer