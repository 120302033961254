import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { UserType } from '../types';

interface UsersState {
    users: Array<UserType>;
    user : UserType;
}

const initialState: UsersState = {
    users : [],
    user : {
        id: 0,
        username: "",
        email: "",
        groups: [],
        permissions: [],
        process_permissions: [],
        process_roles: []
    }
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<Array<UserType>>) => {
            state.users = action.payload;
        },
        setUser: (state, action: PayloadAction<UserType>) => {
            state.user = action.payload;
        }
    },
})

export const { setUsers, setUser } = usersSlice.actions

export const selectUser = (state: RootState) => state.users.user
export const selectUsers = (state: RootState) => state.users.users
export const initialStateUsers = initialState;


export default usersSlice.reducer