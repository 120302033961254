const BaseURL = process.env.REACT_APP_API_URL as string;

type OptionsReqType = {
    method: string,
    headers: Headers,
    body?: string
}

export const get = async (url: string, params: any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        headers.append('Authorization', `Bearer ${token}`);

    }
    const queryParams = params ? '?' + (new URLSearchParams(params)).toString() : '';
    const requestUrl = `${BaseURL + url}${queryParams}`;

    return fetch(requestUrl, {
        method: 'GET',
        headers: headers,
    });
};


export const post = async (url: string, data: any, options?:any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (options) {
        if (options.isFormData) {
            headers.delete('Content-Type');
        }
    }
    
    if (localStorage.getItem('token') && url !== 'login/') {
        const token = localStorage.getItem('token');
        headers.append('Authorization', `Bearer ${token}`);
    }

    let optionsReq : OptionsReqType = {
        method: 'POST',
        headers: headers,
    };

    if ( data ) {
        optionsReq = {
            ...optionsReq,
            body: options?.isFormData ?  data  : JSON.stringify(data),
        }
    }

    return fetch(BaseURL + url, optionsReq);
};


export const put = async (url: string, data: any, options?:any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (options) {
        if (options.isFormData) {
            headers.delete('Content-Type');
        }
    }

    if (localStorage.getItem('token') ) {
        const token = localStorage.getItem('token');
        headers.append('Authorization', `Bearer ${token}`);
    }

    let optionsReq : OptionsReqType = {
        method: 'PUT',
        headers: headers,
    };

    if ( data ) {
        optionsReq = {
            ...optionsReq,
            body: options?.isFormData ?  data  : JSON.stringify(data),
        }
    }

    return fetch(BaseURL + url, optionsReq);
};

export const patch = async (url: string, data: any, options?:any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (options) {
        if (options.isFormData) {
            headers.delete('Content-Type');
        }
    }

    if (localStorage.getItem('token') ) {
        const token = localStorage.getItem('token');
        headers.append('Authorization', `Bearer ${token}`);
    }

    let optionsReq : OptionsReqType = {
        method: 'PATCH',
        headers: headers,
    };

    if ( data ) {
        optionsReq = {
            ...optionsReq,
            body: options?.isFormData ?  data  : JSON.stringify(data),
        }
    }

    return fetch(BaseURL + url, optionsReq);
}

export const del = async (url: string, params: any) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (localStorage.getItem('token') ) {
        const token = localStorage.getItem('token');
        headers.append('Authorization', `Bearer ${token}`);

    }
    const queryParams = params ? '?' + (new URLSearchParams(params)).toString() : '';
    const requestUrl = `${BaseURL + url}${queryParams}`;

    return fetch(requestUrl, {
        method: 'DELETE',
        headers: headers,
    });
}
