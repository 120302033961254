import { useAppSelector, useAppDispatch } from '../../hooks';
import styled from 'styled-components';
import { setPopAlert } from '../../slices/miscSlice';


const PopAlert : React.FC = () => {
    const popAlert = useAppSelector(state => state.misc.popAlert);
    const dispatch = useAppDispatch();
    return popAlert.show ? <AlertStyled className={`alert animate-alert alert-${popAlert.alertType} `}>
      <div
        className="alert-btn-close"
        onClick={() =>
          dispatch(
            setPopAlert({
              message: "",
              show: false,
              alertType: "",
            })
          )
        }
      >
        x
      </div>
      {popAlert.message}
    </AlertStyled> : null;
};

const AlertStyled = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 25px 0 0 25px;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  z-index: 99999;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  bottom: 40px;
  right: 0;
  background: white;
  color: #666;

  &.animate-alert {
    animation: entranceright 0.5s ease-in-out;
  }

  &.alert-danger {
    background-color: var(--red-color);
    color: white;
  }

  &.alert-success {
    background-color: var(--green-color);
    color: white;
  }

  &.alert-warning {
    background-color: var(--orange-color);
    color: white;
  }

  .alert-btn-close {
    width: 25px;
    height: 25px;
    background: #f2f2f2;
    color: #8c8c8c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Bellota Text';
    font-weight: bold;
    font-size: 0.8em;
    cursor: pointer;
    margin-right: 5px;
    line-height: 0.8em;
  }

  @keyframes entranceright {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;

export default PopAlert;
