import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Grid } from "@mui/material";

import { Button, Label } from "../../components/shared/FormElements";

import { usePermissions, printOrderTable } from '../../services';

import { useAppContext } from '../../Context';

import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import { setOrder, resetOrder } from '../../slices/ordersSlice';

import { setLoading } from '../../slices/miscSlice';

import {  OrderType } from '../../types';

import {LoaderTemporal} from '../../components/shared/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import OrderProcessLog from '../orders/OrderProcessLog';

const LoadImage : React.FC<{order_id: number, editMode:boolean}> = ({order_id, editMode}) => {
    const {apiCall} = useAppContext();
    const [blob, setBlob] = useState<string>("Cargando...");
    const dispatch = useAppDispatch();
    const order : OrderType = useSelector((state: any) => state.orders.order);

    useEffect(() => {
        if (!order_id) return ;
        const getImage = async () => {
            const response = await apiCall('GET', `order/${order_id}/boceto/`, null, '', 'Error al obtener la imagen');
            if (response) {
                if (response.image) {
                    setBlob(response.image);
                    dispatch(setOrder({
                        ...order,
                        image_cache: response.image
                    }));
                }else{
                    setBlob("No se ha agregado un boceto");
                }
            }
        }
        if (!order.image_cache) getImage();
        else setBlob(order.image_cache);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return blob !== "No se ha agregado un boceto" && blob !== "Cargando..." ? <img src={blob} alt="boceto" className='width-90' />: <>{blob}</>;
}

const ViewOrderDetail: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {apiCall} = useAppContext();
    const { id } = useParams();

    const [viewMode, setViewMode] = useState<boolean>(true);
    
    const { canView_process } = usePermissions(['view_process'])
    
    const orders : Array<OrderType> = useSelector((state: any) => state.orders.orders);
    const order : OrderType = useSelector((state: any) => state.orders.order);

    

    useEffect(() => {
        if (!id || !canView_process) {
            navigate('/');
            return;
        }

        if (id) {
            setViewMode(true);
            const order = orders.find((order: any) => order.id === parseInt(id));
            if (order) {
                dispatch(setOrder(order));
            } 

            const getOrder = async () => {
                dispatch(setLoading(true));
                const response = await apiCall('GET', `order/${id}/`, null, '', 'Error al obtener la orden');
                dispatch(setLoading(false));
                dispatch(setOrder(response));
            }

            if(!order) getOrder();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBoceto = async () => {
        const resp = await apiCall('GET', `order/${order.id}/boceto/`, null, '', 'Error al obtener la imagen');
        if (resp?.image){
            return resp.image;
        }
        return null;
    }

    const handlePrintOrder = async () => {
        let boceto = null
        if (order.image_cache){
            boceto = order.image_cache;
        }else{
            boceto = await getBoceto();
        }
        dispatch(setLoading(true));
        await printOrderTable(order, boceto);
        dispatch(setLoading(false));
    }

    const goBack = () => {
        dispatch(resetOrder());
        navigate(-1);
    }

    const handleGoToConsecutives = () => {
        console.log ("$order.id", order.id)
        navigate(`consecutivos`);
    }

    if ( id && !order?.id ) return <LoaderTemporal />

    const { customer, line, created_by } = order ? order : { customer: {customer_name:"", customer_city:{city_name:""}}, line: {line_name:""}, created_by: {username:""} };
    const { customer_name, customer_city } = customer;

    return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={7} className="text-left">
            <h2 className="text-left">{viewMode ? `Órden No. ${ order.id}` : "Nueva órden" }</h2>
        </Grid> 
        <Grid item xs={12} sm={1} className="d-flex-center icon-btn">
            <FontAwesomeIcon icon={faPrint} size="2x" className="text-primary" onClick={handlePrintOrder} /> 
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
             <Button className="btn btn-primary" onClick={handleGoToConsecutives}>Consecutivos({order.consecutives_count})</Button>
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
             <Button className="btn btn-primary" onClick={goBack}>Volver</Button>
        </Grid>
    </Grid> 
    <div className="form-container">
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className="text-left">
               
                <div className='form-group'>
                    <Label>Fecha</Label>
                    <div style={{display:"block"}} >{order.order_date}</div>
                </div>
                <div className='form-group'>
                <Label>Cliente</Label>
                     <> {customer_name} </>
                </div>
                <div className='form-group'>
                <Label>Ciudad</Label>
                        <> {customer_city.city_name} </>
                </div>
                <div className='form-group'>
                <Label>Línea</Label>
                     <> {line.line_name} </>
                </div>
                <div className='form-group'>
                <Label>¿Réplica?</Label>
                    <> {order.replica ? order.replica : "N/A"} </>  
                </div>
                <div className='form-group'>
                <Label>¿Es obsequio o garantía?</Label>
                    <> {order.gift ? "Es un obsequio" : (order.warranty ? "Es una garantía" :"N/A" ) } </>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} className="text-left">
                <div className='form-group'>
                <Label>Fecha de inicio de producción</Label>

                        <> {order.production_date_start ? order.production_date_start : "-"} </>
                      
                </div>
                <div className='form-group'>
                <Label>Fecha de fin de producción</Label>
               
                        <> {order.production_date_end ? order.production_date_end : "-"} </>
                    
                </div>
                <div className='form-group'>
                <Label>Fecha de entrega</Label>
             
                        <> {order.delivery_date ? order.delivery_date : "-"} </>
                
                </div>
                <div className='form-group'>
                    <Label>Boceto</Label>
                    <LoadImage order_id={order.id} editMode={false}/>                        
                </div>
            </Grid>
        </Grid> 
    </div>
    { viewMode? <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className="text-right">
           <span className="text-log"><b>Última Modificación:</b> {new Date(order.updated_at).toLocaleString('es-CO')} - <b>Creado por:</b> {created_by.name ? created_by.name : created_by.username } </span>
        </Grid>
    </Grid>:null }
    <br/>
    <OrderProcessLog />
    </div>
}

export default ViewOrderDetail;
