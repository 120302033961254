import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import { OrderProcessLogType } from '../../types';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { setLoading } from '../../slices/miscSlice';
import { setOrderProcessLogs, selectOrderProcessLogs } from '../../slices/processSlice';

import { useAppContext } from '../../Context';



const OrderProcessLog : React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const orderProcessLogs: Array<OrderProcessLogType> = useAppSelector(selectOrderProcessLogs);
    const { apiCall } = useAppContext();

    useEffect(() => {
        getOrderProcessLog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrderProcessLog = async () => {
        dispatch(setLoading(true));
        const response = await apiCall('GET', `order/${id}/processlog/`, null, '', 'Error al obtener el log de procesos');
        dispatch(setLoading(false));
        dispatch(setOrderProcessLogs(response));
    }

   

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="text-left">
                <h3>Log de Procesos</h3>
            </Grid>
            <Grid item xs={12} sm={12} className="text-left">
                <div className="table-container table-log">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Proceso</th>
                                <th>Estado</th>
                                <th>Comentario</th>
                                <th>Fecha de Registro</th>
                                <th>Ejecutado por</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderProcessLogs?.length === 0 ? <tr><td colSpan={4}>No hay entradas en el log de procesos para esta orden</td></tr> : 
                            orderProcessLogs?.map((processlog: OrderProcessLogType, index: number) => {
                                return <tr key={index}>
                                    <td>{processlog?.process?.process_name ? processlog?.process?.process_name : "-"}</td>
                                    <td>{processlog.process_status}</td>
                                    <td>{processlog.process_comment}</td>
                                    <td>{ new Date(processlog.created_at).toLocaleString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                hour12: true,
                              })}</td>
                                    <td>{processlog.executed_by.username}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </Grid>
        </Grid>
    );
}

export default OrderProcessLog;