type Permission = {
    [key: string]: string;
}

const permissions: Permission = {
PERM_VIEW_ORDERS: "view_orders",
PERM_VIEW_CUSTOMERS: "view_customer",
PERM_VIEW_LINES: "view_line",
PERM_VIEW_CITIES: "view_city",
PERM_VIEW_ORDER_CONSECUTIVE: "view_orderconsecutive",
PERM_VIEW_USERS : "view_user",
PERM_VIEW_PROCESS : "view_process",
PERM_VIEW_ORDER_PROCESS_LOG : "view_orderprocesslog",
PERM_VIEW_ORDER_PROCESS_CONSECUTIVE : "view_orderprocessconsecutive",
PERM_VIEW_ORDER_PROCESS_CONSECUTIVE_LOG : "view_orderprocessconsecutivelog",


PERM_ADD_ORDERS: "add_orders",
PERM_ADD_CUSTOMERS: "add_customer",
PERM_ADD_LINES: "add_line",
PERM_ADD_CITIES: "add_city",
PERM_ADD_ORDER_CONSECUTIVE: "add_orderconsecutive",
PERM_ADD_USERS : "add_user",
PERM_ADD_PROCESS : "add_process",
PERM_ADD_ORDER_PROCESS_LOG : "add_orderprocesslog",
PERM_ADD_ORDER_PROCESS_CONSECUTIVE : "add_orderprocessconsecutive",
PERM_ADD_ORDER_PROCESS_CONSECUTIVE_LOG : "add_orderprocessconsecutivelog",


PERM_CHANGE_ORDERS: "change_orders",
PERM_CHANGE_CUSTOMERS: "change_customer",
PERM_CHANGE_LINES: "change_line",
PERM_CHANGE_CITIES: "change_city",
PERM_CHANGE_ORDER_CONSECUTIVE: "change_orderconsecutive",
PERM_CHANGE_USERS : "change_user",
PERM_CHANGE_PROCESS : "change_process",
PERM_CHANGE_ORDER_PROCESS_LOG : "change_orderprocesslog",
PERM_CHANGE_ORDER_PROCESS_CONSECUTIVE : "change_orderprocessconsecutive",
PERM_CHANGE_ORDER_PROCESS_CONSECUTIVE_LOG : "change_orderprocessconsecutivelog",

PERM_DELETE_ORDERS: "delete_orders",
PERM_DELETE_CUSTOMERS: "delete_customer",
PERM_DELETE_LINES: "delete_line",
PERM_DELETE_CITIES: "delete_city",
PERM_DELETE_ORDER_CONSECUTIVE: "delete_orderconsecutive",
PERM_DELETE_USERS : "delete_user",
PERM_DELETE_PROCESS : "delete_process",
PERM_DELETE_ORDER_PROCESS_LOG : "delete_orderprocesslog",
PERM_DELETE_ORDER_PROCESS_CONSECUTIVE : "delete_orderprocessconsecutive",
PERM_DELETE_ORDER_PROCESS_CONSECUTIVE_LOG : "delete_orderprocessconsecutivelog",
}

const groups = {
    GROUP_CONTROL_DE_CALIDAD: "Control de Calidad",
GROUP_PRODUCCION: "Produccion",
GROUP_JEFE_DE_PRODUCCION: "Jefe de Produccion",
GROUP_JEFE_DE_VENTAS: "Jefe de Ventas",
GROUP_VALIDADOR: "Validador",
GROUP_VENDEDOR: "Vendedor",
GROUP_ADMINISTRADOR: "Administrador",
}

const process_permissions : Permission = {
    PERM_ADD_PREALISTAMIENTO: "add_prealistamiento",
    PERM_CHANGE_PREALISTAMIENTO: "change_prealistamiento",
    PERM_VIEW_PREALISTAMIENTO: "view_prealistamiento",
    PERM_ADD_MONTAJE: "add_montaje",
    PERM_CHANGE_MONTAJE: "change_montaje",
    PERM_VIEW_MONTAJE: "view_montaje",
    PERM_ADD_VERIFICACIONMONTAJE: "add_verificacionmontaje",
    PERM_CHANGE_VERIFICACIONMONTAJE: "change_verificacionmontaje",
    PERM_VIEW_VERIFICACIONMONTAJE: "view_verificacionmontaje",
    PERM_ADD_IMPRESION: "add_impresion",
    PERM_CHANGE_IMPRESION: "change_impresion",
    PERM_VIEW_IMPRESION: "view_impresion",
    PERM_ADD_SUBLIMACION: "add_sublimacion",
    PERM_CHANGE_SUBLIMACION: "change_sublimacion",
    PERM_VIEW_SUBLIMACION: "view_sublimacion",
    PERM_ADD_VERIFICACIONSUBLIMACION: "add_verificacionsublimacion",
    PERM_CHANGE_VERIFICACIONSUBLIMACION: "change_verificacionsublimacion",
    PERM_VIEW_VERIFICACIONSUBLIMACION: "view_verificacionsublimacion",
    PERM_ADD_CORTE: "add_corte",
    PERM_CHANGE_CORTE: "change_corte",
    PERM_VIEW_CORTE: "view_corte",
    PERM_ADD_ARMADO: "add_armado",
    PERM_CHANGE_ARMADO: "change_armado",
    PERM_VIEW_ARMADO: "view_armado",
    PERM_ADD_COSTURA: "add_costura",
    PERM_CHANGE_COSTURA: "change_costura",
    PERM_VIEW_COSTURA: "view_costura",
    PERM_ADD_REVISIONYEMPAQUE: "add_revisionyempaque",
    PERM_CHANGE_REVISIONYEMPAQUE: "change_revisionyempaque",
    PERM_VIEW_REVISIONYEMPAQUE: "view_revisionyempaque"
}


const processStatus = {
    EN_PROCESO: "En Proceso",
    FINALIZADO: "Finalizado",
    BLOQUEADO: "Bloqueado",
    POR_INICIAR: "Por Iniciar",
}

const orderStatus = {
    EN_PROCESO: "En Proceso",
    FINALIZADO: "Finalizado",
    BLOQUEADO: "Bloqueado",
    CREADA: "Creada",
    CANCELADA: "Cancelada",
    POR_VALIDAR: "Por Validar",
}

const settings = {
    limitDateDays: 3
}

export const constants = {
    permissions,
    groups,
    process_permissions,
    processStatus,
    orderStatus,
    settings
}

