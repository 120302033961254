const Button = (props :any) => {
    return <button className="button" {...props} type={props.type || "button"} />
}

const TextField = (props : any) => {
    return <input className="input" {...props} />
}

const Label = (props : any) => {
    return <label className="label" {...props} />
}

export { Button, TextField , Label };