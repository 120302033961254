import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePermissions } from '../../services';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEye, faTrash, faUserCheck} from '@fortawesome/free-solid-svg-icons';
import { UserType, ColumnsType } from '../../types';
import { useAppContext } from '../../Context';
import { useAppSelector, useAppDispatch } from '../../hooks';

import { setLoading, setPopConfirmation } from '../../slices/miscSlice';
import { setUser, setUsers, initialStateUsers } from '../../slices/usersSlice';

import { Grid } from '@mui/material';

import { Button } from '../../components/shared/FormElements';
import DataTable from '../../components/shared/DataTable';

const ViewUsers : React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { apiCall } = useAppContext();

    const { canAdd_user, canView_user, canDelete_user, canChange_user } = usePermissions(['add_user', 'view_user', 'delete_user', 'change_user']);
    const users : Array<UserType> = useAppSelector((state) => state.users.users);


    useEffect(() => {
        if (!canView_user) {
            navigate('/');
            return;
        }

        if (users.length === 0) {
            getUsers();   // get users
        }

        dispatch(setUser(initialStateUsers.user)); // clear user
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUsers = async () => {
        dispatch(setLoading(true));
        const response = await apiCall('GET', 'users/', null, '', 'Error al obtener los usuarios');
        dispatch(setLoading(false));
        if (response) {
            dispatch(setUsers(response));
        }
    }



    const actions : Array<any> = [
        {icon: <FontAwesomeIcon icon={faEye} />, tooltip: 'Ver', onClick: (rowData :UserType) => handleViewUser(rowData)} ,
        canDelete_user ? {icon: <FontAwesomeIcon icon={faTrash} />, tooltip: 'Eliminar', onClick: ( rowData :any) => handleDeleteUser (rowData)} : null,
        canChange_user ? {icon: { field: "status", options: { true : <FontAwesomeIcon icon={faBan} />, false: <FontAwesomeIcon icon={faUserCheck} />}} , tooltip: { field: "status" , options : {true: 'Desactivar', false: 'Activar'}}, onClick: (rowData :any) => handleActiveOrInactiveUser(rowData)} : null,
    ];

    const handleViewUser = (user : UserType) => {
        setUser(user);
        navigate(`/usuario/${user.id}`);
    }

    const handleDeleteUser = (user : UserType) => {
        dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea eliminar el usuario?', alertType: 'danger', okFunc: () => deleteUser(user.id) }));
    }

    const deleteUser = async (id : number) => {
        dispatch(setLoading(true));
        const response = await apiCall('DELETE', `user/${id}/`, null, 'Usuario eliminado', 'Error al eliminar el usuario');
        dispatch(setLoading(false));
        if (response) {
            dispatch(setUsers(users.filter((user : UserType) => user.id !== id)));
        }
    }

    const handleActiveOrInactiveUser = (user : UserType) => {
        const textMessage = user.status ? '¿Está seguro que desea desactivar el usuario?' : '¿Está seguro que desea activar el usuario?';
        dispatch(setPopConfirmation({show: true, message: textMessage, alertType: 'danger', okFunc: () => banUser(user.id) }));
    }

    const banUser = async (id : number) => {
        dispatch(setLoading(true));

        const response = await apiCall('PATCH', `user/${id}/toggleStatus/`, null, 'Usuario modificado', 'Error al modificar el usuario');
        dispatch(setLoading(false));
        if (response) {
            dispatch(setUsers(users.map((user : UserType) => user.id === id ? {...user, status: !user.status} : user)));
        }
    }

    const columns : Array<ColumnsType> = [
        {title: 'Nombre', field: 'name'},
        {title: 'Usuario', field: 'username'},
        {title: 'E-Mail', field: 'email'},
        {title: 'Rol', field: 'groups', type: 'array'},
        {title: 'Rol de Procesos', field: 'process_roles', type: 'array'},
        {title: 'Estado', field: 'status', type: 'decorator_bool', cell_class : 'text-center' },
    ];

    const hideConditions = [{
        field : 'is_superuser',
        value : true
    }]

    return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className="text-left">
            <h2 className="text-left">Usuarios</h2>
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
            {canAdd_user && <Button className="btn btn-primary" onClick={() => navigate('/usuario/crear')}>Agregar</Button>}
        </Grid>
    </Grid> 
    <DataTable data={users} columns={columns} actions={actions} groupedOptionBy={'groups'} hideConditions={hideConditions} />
</div>;

}

export default ViewUsers;