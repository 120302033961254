import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

import { OrderProcessLogType, ProcessType } from '../types';

import { initialStateOrders } from './ordersSlice';

interface ProcessState {
    processes: Array<ProcessType>;
    process : ProcessType;
    orderProcessLog: OrderProcessLogType;
    orderProcessLogs: Array<OrderProcessLogType>;
}

const initialState: ProcessState = {
    processes : [],
    process : {
        id : 0,
        process_name : "",
        process_description : "",
        process_icon : "",
        sort_order : 0,
        created_at : "",
        updated_at : "",
        process_slug_permission : "",
    },
    orderProcessLog : {
        id: 0,
        order : initialStateOrders.order,
        process : {
            id : 0,
            process_name : "",
            process_description : "",
            process_icon : "",
            sort_order : 0,
            created_at : "",
            updated_at : "",
            process_slug_permission : "",
        },
        process_status : "",
        process_comment : "",
        created_at : "",
        updated_at : "",
        executed_by : {
            id: 0,
            username: "",
            email: "",
        }
    },
    orderProcessLogs : []
}

export const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        setProcesses: (state, action: PayloadAction<Array<ProcessType>>) => {
            state.processes = action.payload
        },
        setProcess: (state, action: PayloadAction<ProcessType>) => {
            state.process = action.payload
        },
        resetProcess: (state) => {
            state.process = initialState.process
        },
        setOrderProcessLog: (state, action: PayloadAction<OrderProcessLogType>) => {
            state.orderProcessLog = action.payload
        },
        setOrderProcessLogs: (state, action: PayloadAction<Array<OrderProcessLogType>>) => {
            state.orderProcessLogs = action.payload
        },
    },
})

export const { 
    setProcesses, 
    setProcess, 
    resetProcess,
    setOrderProcessLog,
    setOrderProcessLogs
} = processSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectProcesses = (state: RootState) => state.process.processes
export const selectProcess = (state: RootState) => state.process.process
export const selectOrderProcessLog = (state: RootState) => state.process.orderProcessLog
export const selectOrderProcessLogs = (state: RootState) => state.process.orderProcessLogs

export default processSlice.reducer