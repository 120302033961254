import { useEffect, useState } from 'react';

import { useNavigate,  useParams } from "react-router-dom";

import { useAppContext } from '../../Context';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { setConsecutives, setConsecutive, setOrders } from '../../slices/ordersSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash, faPrint } from '@fortawesome/free-solid-svg-icons';

import { usePermissions, printConsecutiveTable, printConsecutiveTableBatch } from '../../services';

import DataTable from '../../components/shared/DataTable';
import { Button } from '../../components/shared/FormElements';

import { AttributesType, ColumnsType, ConsecutiveType,  } from '../../types';
import { setLoading, setPopConfirmation } from '../../slices/miscSlice';

import { Grid } from "@mui/material";

import { constants } from '../../constants';

const ViewConsecutives: React.FC = () => {
    const { apiCall } = useAppContext();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const consecutives = useAppSelector((state : any) => state.orders.consecutives);
    const order = useAppSelector((state : any) => state.orders.order);

     const initialColumns = [
        {title: 'No.', field: 'consecutive_number', cell_class : 'text-center'},
        {title: 'Cliente', field: 'order.customer.customer_name'},
        {title: 'Línea', field: 'line.line_name'},
        {title: 'Fecha de Órden', field: 'order.order_date', cell_class : 'text-center'},
        {title: 'Fecha de Consecutivo', field: 'created_at', type: 'datetime', cell_class : 'text-center'},
        {title: 'Estado', field: 'order.status', type: 'decorator', decorator : { "En Proceso": "secondary", "Finalizado": "success", "Bloqueado": "warning", "Creada": "info", "Cancelada": "error" } , cell_class : 'text-center'}
    ];
    const [columns, setColumns] = useState<ColumnsType[]>(initialColumns);

    const { id } = useParams();

    const { canView_orders, canAdd_orders, canDelete_orders } = usePermissions(['view_orders', 'add_orders', 'change_orders', 'delete_orders', 'add_process']);

    const [enablePrint, setEnablePrint] = useState(false);

    const [selectedForPrint, setSelectedForPrint] = useState<Array<ConsecutiveType>>([]);

    useEffect(() => {

        if (!id) {
            navigate('/ordenes');
            return;
        }


        const getConsecutives = async () => {
            const response = await apiCall('GET', 'order/'+ id +'/consecutives/', null, '', 'Error al obtener los consecutivos');
            if (response) {
                dispatch(setConsecutives(response));
            }
        }
        
        getConsecutives();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
               
    if (!canView_orders) {
        navigate('/')
        return null;
    }

    

    const handleViewConsecutive = (consecutive : ConsecutiveType) => {
        dispatch(setConsecutive(consecutive));
        navigate(`/orden/${id}/consecutivo/${consecutive.id}`);
    }

    const handleDeleteConsecutive = ( consecutive : ConsecutiveType) => {
       dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea eliminar el consecutivo?', alertType: 'danger', okFunc: () => deleteConsecutive(consecutive.id) }));
    }
    const deleteConsecutive = async (consecutive_id : number) => {
        const response = await apiCall('DELETE', `order/${id}/consecutive/${consecutive_id}/`, null, '', 'Error al eliminar el consecutivo');
        if (response) {
            const ordersResponse = await apiCall('GET', 'orders/', null, '', 'Error al obtener las órdenes');

            if (ordersResponse) {
                dispatch(setOrders(ordersResponse));
            }
          
            dispatch(setConsecutives(consecutives.filter((c : ConsecutiveType) => c.id !== consecutive_id)));
        }
    }

    const handlePrintConsecutive= async (consecutive : ConsecutiveType) => {
        let boceto = null;
        const resp = await apiCall('GET', `order/${id}/consecutive/${consecutive.id}/boceto/`, null, '', 'Error al obtener el boceto');
        if (resp?.image){
            boceto = resp.image;
        }
        dispatch(setLoading(true));
        await printConsecutiveTable(consecutive, boceto);
        dispatch(setLoading(false));
    }

    const actions : Array<any> = [
        canView_orders ?  {icon: <FontAwesomeIcon icon={faEye} />, tooltip: 'Ver', onClick: (rowData :ConsecutiveType) => handleViewConsecutive(rowData)} : null,
        canDelete_orders ? {icon: <FontAwesomeIcon icon={faTrash} />, tooltip: 'Eliminar', onClick: ( rowData :any) => handleDeleteConsecutive(rowData)} : null,
        canView_orders ? {icon: <FontAwesomeIcon icon={faPrint} />, tooltip: 'Imprimir', onClick: (rowData :any) => handlePrintConsecutive(rowData)} : null,
    ];

    const filterDate : Array<AttributesType> = [
        {value: 'order.order_date', name: 'Fecha de Órden'},
        {value: 'created_at', name: 'Fecha de Consecutivo   '},
    ]

    const handlePrintSelecteAll = () => {
        setSelectedForPrint(consecutives);
    }

    const handlePrintSelecteNone = () => {
        setSelectedForPrint([]);
    }

    const handlePrintConsecutiveBatch = async () => {
        dispatch(setLoading(true));
        await printConsecutiveTableBatch(selectedForPrint);
        dispatch(setLoading(false));
    }

    const getBocetosForPrint = async () => {
        return new Promise((resolve, reject) => {
            const newConsecutives = consecutives.map(async (c : ConsecutiveType) => {
                let boceto :string = "";
                const resp = await apiCall('GET', `order/${id}/consecutive/${c.id}/boceto/`, null, '', 'Error al obtener el boceto');
                if (resp?.image){
                    boceto = resp.image;
                }
                return {...c, image_cache: boceto};
            } );

            Promise.all(newConsecutives).then((newConsecutives) => {
                // sort by consecutive number
                newConsecutives.sort((a : ConsecutiveType, b : ConsecutiveType) => a.consecutive_number - b.consecutive_number);
                dispatch(setConsecutives(newConsecutives));
                resolve(true);
            }).catch((error) => {
                console.log(error);
                reject(false);
            } );
        });

    }

    const handleSelectItem = (e:any, consecutive : ConsecutiveType) => {
        if (e.target.checked){
            setSelectedForPrint([...selectedForPrint, consecutive]);
        } else {
            setSelectedForPrint(selectedForPrint.filter((c : ConsecutiveType) => c.id !== consecutive.id));
        }
    }

    const handleEnablePrint = async (enable : boolean) => {

        await getBocetosForPrint();

        setEnablePrint(enable);

        const columnsToexport = [
            {title : 'Seleccionar', field: 'select', type: 'checkbox' },
            ...columns
        ]

        if (!enable) { 
            setSelectedForPrint([]);
            setColumns(initialColumns);
        }else {
            setColumns(columnsToexport);
        }

    }

    const ableToAddConsecutives = order.status !== constants.orderStatus.FINALIZADO && order.status !== constants.orderStatus.CANCELADA && order.status !== constants.orderStatus.EN_PROCESO && canAdd_orders;
 
    return <div className="container-wrap">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} className="text-left">
                        <h2 className="text-left">Consecutivos de Orden No. {id}</h2>
                    </Grid>
                    
                    { !enablePrint ? <>
                        <Grid item xs={12} sm={4} className="text-right"></Grid>
                        <Grid item xs={12} sm={1} className="d-flex-center icon-btn">       
                            <FontAwesomeIcon icon={faPrint} size="2x" className="text-primary" onClick={()=>handleEnablePrint(true)} />
                        </Grid>
                        </> 
                        : 
                    <>
                        <Grid item xs={12} sm={1} className="text-center">
                            <Button className="btn btn-primary" onClick={()=>handleEnablePrint(false)}>Cancelar</Button>
                        </Grid>
                        <Grid item xs={12} sm={2} className="text-right">
                            {selectedForPrint.length < consecutives.length ? <Button className="btn btn-primary" onClick={handlePrintSelecteAll}>Seleccionar Todos</Button> : <Button className="btn btn-primary" onClick={handlePrintSelecteNone}>Deseleccionar Todos</Button> }
                        </Grid>
                        <Grid item xs={12} sm={2} className="text-right">
                            { selectedForPrint.length > 0 ?<Button className="btn btn-primary" onClick={handlePrintConsecutiveBatch}>Imprimir</Button>  : null }
                        </Grid>
                    </>
                    }
                    <Grid item xs={12} sm={2} className="text-right">
                    {canView_orders && <Button className="btn btn-primary" onClick={() => navigate('/orden/' + id)}>Regresar a la orden</Button>}
                    </Grid>
                    <Grid item xs={12} sm={2} className="text-right">
                        {<Button title={!ableToAddConsecutives ? "Esta orden ya no puede modificarse":"Agregar nuevo consecutivo"} className="btn btn-primary" onClick={() => navigate('/orden/' + id + '/consecutivo/crear')} disabled={!ableToAddConsecutives}>Agregar</Button>}
                    </Grid>
                </Grid> 
                <DataTable data={consecutives} columns={columns} actions={actions} filterByDateBy={filterDate} selectItem={handleSelectItem}  itemsSelected={selectedForPrint} />
            </div>;
}

    export default ViewConsecutives;