import { Route, Routes, BrowserRouter,Navigate} from 'react-router-dom';

import { useAppContext } from './Context';

// pages 
import Login from './pages/user/Login';
import ViewOrders from './pages/orders/ViewOrders';
import CreateEditOrder from './pages/orders/CreateEditOrder';
import ViewProcesses from './pages/processes/ViewProcesses';
import ViewOrdersProcess from './pages/processes/ViewOrdersProcess';
import ViewOrderDetail from './pages/processes/ViewOrderDetail';
import ViewUsers from './pages/user/ViewUsers';
import CreateEditUser from './pages/user/CreateEditUser';
import ViewConsecutives from './pages/consecutives/ViewConsecutives';
import CreateEditConsecutive from './pages/consecutives/CreateEditConsecutive';
import ViewStats from './pages/stats/ViewStats';
import ViewSettings from './pages/settings/ViewSettings';
import ViewListName from './pages/list/ViewListName';
import ViewOrderConsecutivesProcess from './pages/processes/ViewOrderConsecutivesProcess';
import ViewConsecutiveDetail from './pages/processes/ViewConsecutiveDetail';
import ViewOrdersFinished from './pages/orders/ViewOrdersFinished';
import ViewOrdersProcessed from './pages/orders/ViewOrdersProcessed';
import ViewOrdersDeleted from './pages/orders/ViewOrdersDeleted';
import ViewOrdersUnvalidated from './pages/orders/ViewOrdersToValidate';


// components
import {Loader } from './components/shared/Loader';
import PopAlert from './components/shared/PopAlert';
import Sidebar from './components/Sidebar';
import PopConfirmation from './components/shared/PopConfirmation';

// styles
import './App.css';
import Home from './pages/Home';


const App : React.FC = () => {
  const { auth } = useAppContext();
  return (
      <BrowserRouter>
      {auth.isLogged ? <Sidebar /> : null}
      <Routes>
        <Route path="/ordenes" element={auth.isLogged ? <ViewOrders /> : <Login />} />
        <Route path="/ordenes/finalizadas" element={auth.isLogged ? <ViewOrdersFinished /> : <Login />} />
        <Route path="/ordenes/proceso" element={auth.isLogged ? <ViewOrdersProcessed />: <Login />} />
        <Route path="/ordenes/eliminadas" element={auth.isLogged ? <ViewOrdersDeleted /> : <Login />} />
        <Route path="/ordenes/por-validar" element={auth.isLogged ? <ViewOrdersUnvalidated /> : <Login />} />
        <Route path="/orden/:id" element={auth.isLogged ? <CreateEditOrder /> : <Login />} />
        <Route path="/orden/:id/detalle" element={auth.isLogged ? <ViewOrderDetail /> : <Login />} />
        <Route path="/orden/crear" element={auth.isLogged ? <CreateEditOrder /> : <Login />} />

        <Route path="/orden/:id/consecutivos" element={auth.isLogged ? <ViewConsecutives /> : <Login />} />
        <Route path="/orden/:id/consecutivo/:consecutive_id" element={auth.isLogged ? <CreateEditConsecutive /> : <Login />} />
        <Route path="/orden/:id/consecutivo/crear" element={auth.isLogged ? <CreateEditConsecutive /> : <Login />} />
        <Route path="/orden/:id/detalle/consecutivos" element={auth.isLogged ? <ViewOrderConsecutivesProcess /> : <Login />} />
        <Route path="/orden/:id/detalle/consecutivos/:consecutive_id" element={auth.isLogged ? <ViewConsecutiveDetail /> : <Login />} />

        <Route path="/procesos" element={auth.isLogged ? <ViewProcesses /> : <Login />} /> 
        <Route path="/proceso/:id" element={auth.isLogged ? <ViewOrdersProcess /> : <Login />} />

        <Route path="/usuarios" element={auth.isLogged ? <ViewUsers/> : <Login />} />
        <Route path="/usuario/crear" element={auth.isLogged ? <CreateEditUser /> : <Login />} />
        <Route path="/usuario/:id" element={auth.isLogged ? <CreateEditUser /> : <Login />} />

        <Route path="/estadisticas" element={auth.isLogged ? <ViewStats /> : <Login />} />

        <Route path="/configuraciones" element={auth.isLogged ? <ViewSettings /> : <Login />} />

        <Route path="/" element={auth.isLogged ? <ViewStats /> : <Login />} />
        <Route path="/home" element={auth.isLogged ? <Home /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/lista" element={<ViewListName />} />
        <Route path="/lista/:token" element={<ViewListName />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Loader />
      <PopAlert />
      <PopConfirmation />
    </BrowserRouter>   
  );
}

export default App;
