import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

import { ConsecutiveType, OrderType } from '../types';


// Define a type for the slice state
interface OrdersState {
    orders: Array<OrderType>;
    ordersFinished: Array<OrderType>;
    ordersInProcess: Array<OrderType>;
    ordersDeleted: Array<OrderType>;
    ordersToValidate: Array<OrderType>;
    order : OrderType;
    consecutives: Array<ConsecutiveType>;
    consecutive : ConsecutiveType;
}

// Define the initial state using that type
const initialState: OrdersState = {
    orders : [],
    ordersFinished : [],
    ordersInProcess : [],
    ordersDeleted : [],
    ordersToValidate : [],
    order : {
        id: 0,
        customer: {
            id: 0,
            siigo_id: "",
            customer_name: "",
            customer_city: {
                id: 0,
                city_name: "",
                state_name: "",
                country_name: "",
            }
        },
        city: {
            id: 0,
            city_name: "",
            state_name: "",
            country_name: "",
        },
        line: {
            id: 0,
            line_name: "",
        },
        created_by: {
            id: 0,
            username: "",
            email: "",
        },
        order_date: '',
        production_date_start: '',
        production_date_end: '',
        delivery_date: '',
        replica: "",
        gift: false,
        warranty: false,
        image: "",
        status: "",
        created_at: '',
        updated_at: '',
        quotation_number: "",
        clothing_quantity: 0,
        clothing_neck: "",
        clothing_cut: "",
        socks_quantity: 0,
        socks_color: "",
        current_process:""
    },
    consecutives: [],
    consecutive : {
        id : 0,
        order : {
            id: 0,
            customer: {
                id: 0,
                siigo_id: "",
                customer_name: "",
                customer_city: {
                    id: 0,
                    city_name: "",
                    state_name: "",
                    country_name: "",
                }
            },
            city: {
                id: 0,
                city_name: "",
                state_name: "",
                country_name: "",
            },
            line: {
                id: 0,
                line_name: "",
            },
            created_by: {
                id: 0,
                username: "",
                email: "",
            },
            order_date: '',
            production_date_start: '',
            production_date_end: '',
            delivery_date: '',
            replica: "",
            gift: false,
            warranty: false,
            image: "",
            status: "",
            consecutives_count: 0,
            image_cache: "",
            quotation_number: "",
            clothing_quantity: 0,
            clothing_neck: "",
            clothing_cut: "",
            socks_quantity: 0,
            socks_color: "",
            created_at: '',
            updated_at: '',
            current_process: ""
        },
        consecutive_number : 0,
        created_by : {
            id: 0,
            username: "",
            email: "",
        },
        replica : "",
        gift : false,
        warranty : false,
        clothing_quantity : 0,
        image : "",
        created_at : "",
        updated_at : "",
        socks_quantity : 0,
        socks_color : "",
    }
}

export const ordersSlice = createSlice({
    name: 'orders',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setOrders: (state, action: PayloadAction<Array<OrderType>>) => {
            state.orders = action.payload;
        },
        setOrdersFinished: (state, action: PayloadAction<Array<OrderType>>) => {
            state.ordersFinished = action.payload;
        },
        setOrdersInProcess: (state, action: PayloadAction<Array<OrderType>>) => {
            state.ordersInProcess = action.payload;
        },
        setOrdersDeleted: (state, action: PayloadAction<Array<OrderType>>) => {
            state.ordersDeleted = action.payload;
        },
        setOrdersToValidate: (state, action: PayloadAction<Array<OrderType>>) => {
            state.ordersToValidate = action.payload;
        },
        setOrder: (state, action: PayloadAction<OrderType>) => {
            state.order = action.payload;
        },
        resetOrder : (state) => {
            state.order = initialState.order;
        },
        resetOrders : (state) => {
            state.orders = initialState.orders;
        },
        deleteOrder : (state, action: PayloadAction<number>) => {
            state.orders = state.orders.filter((order) => order.id !== action.payload);
        },
        setConsecutives: (state, action: PayloadAction<Array<ConsecutiveType>>) => {
            state.consecutives = action.payload;
        },
        setConsecutive: (state, action: PayloadAction<ConsecutiveType>) => {
            state.consecutive = action.payload;
        },
        resetConsecutive : (state) => {
            state.consecutive = initialState.consecutive;
        },
        resetConsecutives : (state) => {
            state.consecutives = initialState.consecutives;
        },
    },
})

export const { 
    setOrders, 
    setOrder, 
    resetOrder,
    setConsecutives,
    setConsecutive, 
    resetConsecutive,
    resetConsecutives,
    setOrdersFinished,
    setOrdersInProcess,
    setOrdersDeleted,
    setOrdersToValidate,
    deleteOrder,
    resetOrders,
} = ordersSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectOrder = (state: RootState) => state.orders.order
export const selectOrders = (state: RootState) => state.orders.orders
export const selectOrdersFinished = (state: RootState) => state.orders.ordersFinished
export const selectOrdersInProcess = (state: RootState) => state.orders.ordersInProcess
export const selectOrdersDeleted = (state: RootState) => state.orders.ordersDeleted
export const selectOrdersToValidate = (state: RootState) => state.orders.ordersToValidate
export const initialStateOrders = initialState;

export default ordersSlice.reducer