import { useEffect } from "react";
import { useAppContext } from "../../Context";
import { AttributesType, ColumnsType, OrderType } from "../../types";

import { useNavigate } from "react-router-dom";

import DataTable from '../../components/shared/DataTable';

// hooks redux
import { useSelector } from "react-redux";
import { useAppDispatch } from '../../hooks';
import { setOrder, setOrdersDeleted } from '../../slices/ordersSlice';
import { setPopConfirmation } from '../../slices/miscSlice';

import { usePermissions, useGroups } from '../../services';
import { Grid } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEye, faTrash, faUndo} from '@fortawesome/free-solid-svg-icons';
import { constants } from "../../constants";

const ViewOrdersDeleted : React.FC = () => {
    const { apiCall } = useAppContext();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ordersDeleted = useSelector((state : any) => state.orders.ordersDeleted);

    const { canView_orders, canDelete_orders } = usePermissions(['view_orders', 'delete_orders']);
    const { 
        isAdministrador, 
    } = useGroups([
        'Administrador', 
    ]);

    useEffect(() => {
        const getOrders = async () => {
            const response = await apiCall('GET', 'orders/deleted/', null, '', 'Error al obtener las ordenes');
            if (response) {
                dispatch(setOrdersDeleted(response));
            }
        }
        
        getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
               
    if (!canView_orders) {
        navigate('/')
        return null;
    }
    
    const hoyOnlyDate = new Date();
    hoyOnlyDate.setHours(0,0,0,0);

    const conditionDecorator = { 
        operator: '<', 
        value: hoyOnlyDate, 
        class_decorator : 'text-danger', 
        type:'date',
        limit : constants.settings.limitDateDays
    } 

    const decoratorEstado = { 
        "Finalizado": "success", 
        "Bloqueado": "warning", 
        "Creada": "info", 
        "Cancelada": "error" 
    }

    const columns : Array<ColumnsType> = [
        {title: 'ID', field: 'id'},
        {title: 'Cliente', field: 'customer.customer_name'},
        {title: 'Línea', field: 'line.line_name'},
        {title: 'Cons', field: 'consecutives_count', cell_class : 'text-center'},
        {title: 'Vendedor', field: 'created_by.username', cell_class : 'text-center'},
        {title: 'Fecha', field: 'order_date', cell_class : 'text-center'},
        {title: 'Inicio Prod.', field: 'production_date_start', cell_class : 'text-center'},
        {title: 'Fin Prod.', field: 'production_date_end', type: 'conditional_decorator', decorator: conditionDecorator , cell_class : 'text-center'},
        {title: 'Entrega', field: 'delivery_date', type: 'conditional_decorator', decorator: conditionDecorator , cell_class : 'text-center'},
        {title: 'Estado', field: 'current_process', type: 'decorator', decorator : decoratorEstado , cell_class : 'text-center'}
    ];

    const handleViewOrder = (order : OrderType) => {
        dispatch(setOrder(order));
        navigate(`/orden/${order.id}`);
    }

    const handleDeleteOrder = ( order : OrderType) => {
       dispatch(setPopConfirmation({show: true, message: '¿Está seguro que desea eliminar la orden? Cuidado, esta acción es IRREVERSIBLE', alertType: 'danger', okFunc: () => deleteOrder(order.id) }));
    }
    const deleteOrder = async (order_id : number) => {
        const response = await apiCall('DELETE', `order/${order_id}/delete/`, null, '', 'Error al eliminar la orden');
        if (response) {
            dispatch(setOrdersDeleted(ordersDeleted.filter((o : OrderType) => o.id !== order_id)));
        }
    }

    const handleRecovery = async (order : OrderType) => {
        const response = await apiCall('PUT', `order/${order.id}/recovery/`, null, '', 'Error al recuperar la orden');
        if (response) {
            dispatch(setOrdersDeleted(ordersDeleted.filter((o : OrderType) => o.id !== order.id)));
        }
    }

    const actions : Array<any> = [
        canView_orders ?  {icon: <FontAwesomeIcon icon={faEye} />, tooltip: 'Ver', onClick: (rowData :OrderType) => handleViewOrder(rowData)} : null,
        canDelete_orders ? {icon: <FontAwesomeIcon icon={faTrash} />, tooltip: 'Eliminar Completamente', onClick: ( rowData :any) => handleDeleteOrder (rowData)} : null,
        isAdministrador ? {icon: <FontAwesomeIcon icon={faUndo} />, tooltip: 'Recuperar', onClick: ( rowData :any) => handleRecovery (rowData)} : null,
    ];

    const filterDate : Array<AttributesType> = [
        {value: 'order_date', name: 'Fecha de Creación'},
        {value: 'production_date_start', name: 'Fecha de Inicio de Produccion'},
        {value: 'production_date_end', name: 'Fecha de Fin de Produccion'},
        {value: 'delivery_date', name: 'Fecha de Entrega'},
    ]
 
    return <div className="container-wrap">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={10} className="text-left">
                        <h2 className="text-left">Órdenes Eliminadas</h2>
                    </Grid>
                </Grid> 
                <DataTable data={ordersDeleted} columns={columns} actions={actions} filterByDateBy={filterDate} />
            </div>;
}

export default ViewOrdersDeleted;