import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface LinesState {
    lines: Line[];
    line : Line;
}

export interface Line{
    id: number;
    line_name: string;
}

const initialState: LinesState = {
    lines : [],
    line : {
        id: 0,
        line_name: "",
    }
}

export const linesSlice = createSlice({
    name: 'lines',
    initialState,
    reducers: {
        setLines: (state, action: PayloadAction<Line[]>) => {
            state.lines = action.payload;
        },
        setLine: (state, action: PayloadAction<Line>) => {
            state.line = action.payload;
        }
    },
})

export const { setLines, setLine } = linesSlice.actions

export const selectLine = (state: RootState) => state.lines.line
export const selectLines = (state: RootState) => state.lines.lines

export default linesSlice.reducer