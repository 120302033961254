import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { 
    Autocomplete, 
    Grid, 
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";

import { Button, Label } from "../../components/shared/FormElements";

import { usePermissions, useGroups, printOrderTable } from '../../services';

import { useAppContext } from '../../Context';

import { useAppDispatch, useAppSelector } from '../../hooks';

import { initialStateOrders, setOrder, setOrders } from '../../slices/ordersSlice';
import { setCustomers } from '../../slices/customersSlice';

import { setLines } from '../../slices/linesSlice';
import { setLoading, setPopAlert } from '../../slices/miscSlice';

import { compressImage } from '../../Utils';
import { CustomerType, LineType, OrderType, CityType } from '../../types';

import {LoaderTemporal} from '../../components/shared/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import OrderProcessLog from './OrderProcessLog';
import { constants } from '../../constants';


const LoadImage : React.FC<{order_id: number, editMode:boolean}> = ({order_id, editMode}) => {
    const {apiCall} = useAppContext();
    const [blob, setBlob] = useState<string>("Cargando...");
    const dispatch = useAppDispatch();
    const order : OrderType = useAppSelector((state: any) => state.orders.order);

    useEffect(() => {
        if (!order_id) return ;
        const getImage = async () => {
            const response = await apiCall('GET', `order/${order_id}/boceto/`, null, '', 'Error al obtener la imagen');
            if (response) {
                if (response.image) {
                    setBlob(response.image);
                    dispatch(setOrder({
                        ...order,
                        image_cache: response.image
                    }));
                }else{
                    setBlob("No se ha agregado un boceto");
                }
            }
        }
        if (!order.image_cache) getImage();
        else setBlob(order.image_cache);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return blob !== "No se ha agregado un boceto" && blob !== "Cargando..." ? <> { editMode ? <><b>Actual:</b> <br/></>: null } <img src={blob} alt="boceto" className='width-90' /></>: <>{blob}</>;
}


const CreateEditOrder: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {apiCall} = useAppContext();
    const { id } = useParams();

    const [viewMode, setViewMode] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    
    const { 
        canView_orders, 
        canAdd_orders, 
        canChange_orders, 
        canAdd_process
    } = usePermissions([
        'view_orders', 
        'add_orders', 
        'change_orders', 
        'delete_orders',
        'add_process'
    ]);
    const { isAdministrador, isJefedeProduccion, isValidador } = useGroups(['Administrador', 'Jefe de Produccion', 'Validador']);
    

    const orders : Array<OrderType> = useAppSelector((state: any) => state.orders.orders);
    const order : OrderType = useAppSelector((state: any) => state.orders.order);
    const customers : Array<CustomerType> = useAppSelector((state: any) => state.customer.customers);
    const lineas : Array<LineType> = useAppSelector((state: any) => state.lines.lines);

    const [currentCustomer, setCurrentCustomer] = useState<CustomerType>(useAppSelector((state: any) => state.customer.initialState));
    const [currentOrder, setCurrentOrder] = useState<OrderType>(useAppSelector((state: any) => state.orders.initialState));

    const [blobBoceto, setBlobBoceto] = useState<Blob | null>(null);

    const [obsequioGarantia , setObsequioGarantia] = useState<string>('');

    useEffect(() => {
     
        if (customers.length === 0) {
            const getCustomers = async () => {
                dispatch(setLoading(true));
                const response = await apiCall('GET', 'customers/', null, '', 'Error al obtener los clientes');
                dispatch(setLoading(false));
                dispatch(setCustomers(response));
            }
            getCustomers();
        }

        if (lineas.length === 0) {
            const getLines = async () => {
                dispatch(setLoading(true));
                const response = await apiCall('GET', 'lines/', null, '', 'Error al obtener las líneas');
                dispatch(setLoading(false));
                dispatch(setLines(response));
            }
            getLines();
        }

        if (!id) {
            dispatch(setOrder(initialStateOrders.order));
            setCurrentOrder(initialStateOrders.order);
            if (!canAdd_orders) {
                navigate('/')
                return;
            }
            return;
        }

        if (id && !canView_orders) {
            navigate('/')
            return;
        }

        setViewMode(true);
        const order = orders.find((order: any) => order.id === parseInt(id));
        if (order) {
            dispatch(setOrder(order));
            setCurrentOrder(order);
            if (order.gift) setObsequioGarantia('gift');
            if (order.warranty) setObsequioGarantia('warranty');
        } 

        const getOrder = async () => {
            const response = await apiCall('GET', `order/${id}/`, null, '', 'Error al obtener la orden');
            dispatch(setOrder(response));
            setCurrentOrder(response);
            if (response?.gift) setObsequioGarantia('gift') 
            if (response?.warranty) setObsequioGarantia('warranty');
        }

        if(!order) getOrder();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async() => {
        let body = new FormData();

        if (!isValidador){
            if (!currentOrder) {
                dispatch(setPopAlert({message: 'No hay datos para guardar', show: true, alertType: 'danger'}));
                return; 
            }

            if (!currentOrder.customer) {
                dispatch(setPopAlert({message: 'Selecciona un cliente', show: true, alertType: 'danger'}));
                return;
            }
            if (!currentOrder.line) {
                dispatch(setPopAlert({message: 'Selecciona una línea', show: true, alertType: 'danger'}));
                return;
            }

            if (isNaN(currentOrder.clothing_quantity) || currentOrder.clothing_quantity < 0) {
                dispatch(setPopAlert({message: 'Ingresa la cantidad de prendas', show: true, alertType: 'danger'}));
                return;
            }

            if (!currentOrder.clothing_neck) {
                dispatch(setPopAlert({message: 'Ingresa el cuello de la prenda', show: true, alertType: 'danger'}));
                return;
            }

            if (!currentOrder.clothing_cut) {
                dispatch(setPopAlert({message: 'Ingresa el corte de la prenda', show: true, alertType: 'danger'}));
                return;
            }

            if (isNaN(currentOrder.socks_quantity) || currentOrder.socks_quantity < 0) {
                dispatch(setPopAlert({message: 'Ingresa la cantidad de medias', show: true, alertType: 'danger'}));
                return;
            }

            body.append('customer', currentOrder.customer.id.toString());
            body.append('line', currentOrder.line.id.toString());
            body.append('replica', currentOrder.replica ? currentOrder.replica : '');
            body.append('production_date_start', currentOrder.production_date_start ? currentOrder.production_date_start : '');
            body.append('production_date_end', currentOrder.production_date_end ? currentOrder.production_date_end : '');
            body.append('delivery_date', currentOrder.delivery_date ? currentOrder.delivery_date : '');
            body.append('quotation_number', currentOrder.quotation_number ? currentOrder.quotation_number : '');
            body.append('clothing_quantity', currentOrder.clothing_quantity.toString());
            body.append('clothing_neck', currentOrder.clothing_neck ? currentOrder.clothing_neck : '');
            body.append('clothing_cut', currentOrder.clothing_cut ? currentOrder.clothing_cut : '');
            body.append('socks_quantity', currentOrder.socks_quantity.toString());
            body.append('socks_color', currentOrder.socks_color ? currentOrder.socks_color : '');

            
            if (obsequioGarantia && obsequioGarantia !== '') {
                body.append(obsequioGarantia, '1');
            }

            if (currentOrder.image){
            body.append('image', blobBoceto ? blobBoceto : '');
            }
        }else{
            body.append('production_date_start', currentOrder.production_date_start ? currentOrder.production_date_start : '');
            body.append('production_date_end', currentOrder.production_date_end ? currentOrder.production_date_end : '');
            body.append('delivery_date', currentOrder.delivery_date ? currentOrder.delivery_date : '');

            if (currentOrder.image){
                body.append('image', blobBoceto ? blobBoceto : '');
                }
        }

        const options = {
            isFormData : true
        };
        let response : any;
        if (!editMode) {
            response = await apiCall('POST', 'order/', body, '', 'Error al guardar la orden', options);
        } else {
            response = await apiCall('PUT', `order/${currentOrder.id}/`, body, '', 'Error al guardar la orden', options);
        }
        
        if (response) {
            dispatch(setPopAlert({message: 'Orden guardada correctamente', show: true, alertType: 'success'}));
            const response = await apiCall('GET', 'orders/', null, '', 'Error al obtener las ordenes');
            if (response) {
                dispatch(setOrders(response));
                const order = response?.filter((order: OrderType) => order.id === currentOrder.id);
                setCurrentOrder(order);
                setEditMode(false);
                setViewMode(true);
            }
        }
    }

    const obsequioGarantiaMap: Record<string, string> = {
        '': 'Ninguno',
        'gift': 'Obsequio',
        'warranty': 'Garantía'
    };

    const handleChange = (event: React.MouseEvent<HTMLElement>, newObsequioGarantia: string) => {
        setObsequioGarantia(newObsequioGarantia);
    }

    useEffect(() => {
        if (!currentOrder?.image) return;
        const compress = async () => {
            dispatch(setLoading(true));
            const blob : Blob = await compressImage(currentOrder.image) as Blob;
            setBlobBoceto(blob);
            dispatch(setLoading(false));
        }
        compress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder?.image]);

    const handleCustomerSelected = async (event: any, value: any) => {
        if (!value?.id) return;
        if (value.customer_city_name){
            const city : CityType = {
                id: value.customer_city,
                city_name: value.customer_city_name,
                state_name: value.customer_state_name,
                country_name: value.customer_country_name
            }
            setCurrentOrder({
                ...currentOrder,
                customer: value,
                city: city
            });
            return;
        }

        const body = {
            onlyCity: true
        };

        const response = await apiCall('GET', `customers/${value.id}/`, body, '', 'Error al obtener el cliente');
        if (response.address.city) {

            const city : CityType = {
                id: response.city_id,
                city_name: response.address.city.city_name,
                state_name: response.address.city.state_name,
                country_name: response.address.city.country_name
            }

            const newCustomer : CustomerType = {
                ...value,
                customer_city : city
            }

            setCurrentCustomer(newCustomer);
            
            setCurrentOrder({
                ...currentOrder,
                customer: newCustomer,
                city: response.address.city
            });
            /* find customer in customers and update it*/
            const newCustomers = customers.map((customer: any) => {
                if (customer.id === currentCustomer?.id) {
                    return currentCustomer;
                }
                return customer;
            }); 
            dispatch(setCustomers(newCustomers)); 

        }
    }

    const handleEditar = () => {
        setViewMode(false);
        setEditMode(true);
    }

    const getBoceto = async () => {
        const resp = await apiCall('GET', `order/${order.id}/boceto/`, null, '', 'Error al obtener la imagen');
        if (resp?.image){
            return resp.image;
        }
        return null;
    }

    const handlePrintOrder = async () => {
        let boceto = null
        if (order.image_cache){
            boceto = order.image_cache;
        }else{
            boceto = await getBoceto();
        }
        dispatch(setLoading(true));
        await printOrderTable(order, boceto);
        dispatch(setLoading(false));
    }

    const goToConsecutives = () => {
        dispatch(setOrder(currentOrder));
        navigate('/orden/' + id + '/consecutivos');
    }

    const addProcess = async () => { 
        const order_id = order.id;
        const response = await apiCall('POST', `order/${order_id}/add_process/`, null, '', 'Error al enviar la orden a producción');
        if (response) {
            // update order status
            const newOrders = orders.map((o : OrderType) => {
                if (o.id === order_id) {
                    return {...o, status: constants.orderStatus.EN_PROCESO}
                }
                return o;
            });
            dispatch(setOrders(newOrders));
            dispatch(setOrder({...order, status: constants.orderStatus.EN_PROCESO}));
        }
    }

    if ( customers?.length === 0 || lineas?.length === 0 ) return <LoaderTemporal />

    if ( id && !order?.id ) return <LoaderTemporal />

    const { customer, line, created_by, quotation_number } = order ? order : { customer: {customer_name:"", customer_city:{city_name:""}}, line: {line_name:""}, created_by: {username:""}, quotation_number: ""};
    const { customer_name, customer_city } = customer;

    const ableToAddConsecutives = id && viewMode && order.is_deleted === false;

    const ableToSendToProduction = id && viewMode && canAdd_process && order.status === constants.orderStatus.CREADA && order.is_deleted === false;

    const isAbleToEdit = isAdministrador ? true : (canChange_orders && order.is_deleted === false && (order.status !== constants.orderStatus.EN_PROCESO  || order.status === constants.orderStatus.FINALIZADO || order.status === constants.orderStatus.BLOQUEADO) );

    return <div className="container-wrap">
    <Grid container spacing={2}>
        <Grid item xs={12} sm={5} className="text-left">
            <h2 className="text-left">{viewMode || editMode ? `Órden No. ${ order.id}` : "Nueva órden" }</h2>
        </Grid> 
        <Grid item xs={12} sm={2} className="text-center">
            { ableToSendToProduction   ? <Button className="btn btn-primary" onClick={addProcess}>Enviar a Producción</Button> : null}
        </Grid>
        <Grid item xs={12} sm={1} className="d-flex-center icon-btn">
            { viewMode ? <FontAwesomeIcon icon={faPrint} size="2x" className="text-primary" onClick={handlePrintOrder} /> : null}
        </Grid>
        <Grid item xs={12} sm={2} className="text-center">
            { ableToAddConsecutives ? <Button className="btn btn-primary" onClick={goToConsecutives}>Consecutivos {order.consecutives_count ? "(" + order.consecutives_count + ")" : "(0)" }</Button> : null}
        </Grid>

        <Grid item xs={12} sm={2} className="text-right">
            {canView_orders && <Button className="btn btn-primary" onClick={() => navigate('/ordenes')}>Ir a Órdenes</Button>}
        </Grid>
    </Grid> 
    <div className="form-container">
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} className="text-left">
                {viewMode ?
                    <div className='form-group'>
                        <Label>Fecha</Label>
                        <div style={{display:"block"}} >{order.order_date}</div>
                    </div>
                    :
                    null}
                <div className='form-group'>
                <Label>Cliente</Label>
                    {viewMode ? 
                        <> {customer_name} </>
                        : 
                        !isValidador ?<Autocomplete 
                        options={customers ? customers : []}
                        getOptionLabel={(option : CustomerType) => option.customer_name.toUpperCase()}
                        renderInput={(params) => <TextField className='form-control' {...params} label="Escribe el nombre del cliente..." />} 
                        onChange={handleCustomerSelected }
                        value={currentOrder ? currentOrder.customer : null}
                        renderOption={(props, option) => {
                            return (
                            <li {...props} key={option.id}>
                                <span>
                                    {option.customer_name}
                                </span>
                            </li>
                            );
                        }
                        }    
                        /> : <>{customer_name}</>
                    }
                </div>
                <div className='form-group'>
                <Label>Ciudad</Label>
                    {viewMode ? 
                        <> {customer_city.city_name} </>
                        : 
                        <div style={{display:'block'}}>{currentOrder ? (currentOrder.customer?.customer_city.city_name ? currentOrder.customer?.customer_city.city_name : '-') : '-'} </div>
                    }
                </div>
                <div className='form-group'>
                <Label>Línea</Label>
                    {viewMode ? 
                        <> {line.line_name} </>
                        : 
                        !isValidador ?<Autocomplete
                            options={lineas ? lineas : []}
                            getOptionLabel={(option : LineType) => option.line_name.toUpperCase()}
                            renderInput={(params) => <TextField className='form-control' {...params} label="Escribe el nombre de la línea..." />} 
                            onChange={(event: any, value: any) => {
                                setCurrentOrder({
                                    ...currentOrder,
                                    line: value
                                });
                            }}
                            value={currentOrder ? currentOrder.line : null}
                            renderOption={(props, option : LineType) => {
                                return (
                                <li {...props} key={option.id}>
                                    <span>
                                        {option.line_name}
                                    </span>
                                </li>
                                );
                            }
                            }    
                            /> : <>{line.line_name} </>
                    }
                </div>
                <div className='form-group'>
                    <Label>Cantidad de Prendas</Label>
                    {viewMode ? 
                        <> {order.clothing_quantity ? order.clothing_quantity : "N/A"} </>
                        : 
                        !isValidador ?<TextField className='form-control' type="number" value={currentOrder?.clothing_quantity ? currentOrder.clothing_quantity : 0 }
                        onChange={(event) => {
                            // prevent negative values
                            if (parseInt(event.target.value) < 0) {
                                return;
                            }
                            setCurrentOrder({
                                ...currentOrder,
                                clothing_quantity: parseInt(event.target.value)
                            });
                        }} 

                        onFocus={(event) => {
                            event.target.select();
                        }}
                        /> : <>{order.clothing_quantity ? order.clothing_quantity : "N/A"} </>
                    }
                </div>
                <div className='form-group'>
                    <Label>Cuello</Label>
                    {viewMode ? 
                        <> {order.clothing_neck ? order.clothing_neck : "N/A"} </>
                        : 
                        !isValidador ? <TextField className='form-control' type="text" value={currentOrder?.clothing_neck ? currentOrder.clothing_neck : ''}
                        onChange={(event) => {
                            setCurrentOrder({
                                ...currentOrder,
                                clothing_neck: event.target.value
                            });
                        }} /> : <>{order.clothing_neck ? order.clothing_neck : "N/A"} </>
                    }
                </div>
                <div className='form-group'>
                    <Label>Corte</Label>
                    {viewMode ? 
                        <> {order.clothing_cut ? order.clothing_cut : "N/A"} </>
                        : 
                        !isValidador ?<TextField className='form-control' type="text" value={currentOrder?.clothing_cut ? currentOrder.clothing_cut : ''}
                        onChange={(event) => {
                            setCurrentOrder({
                                ...currentOrder,
                                clothing_cut: event.target.value
                            });
                        }} /> : <>{order.clothing_cut ? order.clothing_cut : "N/A"} </>
                    }
                </div>
                <div className='form-group'>
                    <Label>Cantidad de Medias</Label>
                    {viewMode ? 
                        <> {order.socks_quantity ? order.socks_quantity : "N/A"} </>
                        : 
                        !isValidador ? <TextField className='form-control' type="number" value={currentOrder?.socks_quantity ? currentOrder.socks_quantity : 0}
                        onChange={(event) => {
                            if (parseInt(event.target.value) < 0) {
                                return;
                            }
                            setCurrentOrder({
                                ...currentOrder,
                                socks_quantity: parseInt(event.target.value)
                            });
                            
                        }} 
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        /> : <>{order.socks_quantity ? order.socks_quantity : "N/A"} </>
                    }
                </div>
                <div className='form-group'>
                    <Label>Color de Medias</Label>
                    {viewMode ? 
                        <> {order.socks_color ? order.socks_color : "N/A"} </>
                        : 
                        !isValidador ? <TextField className='form-control' type="text" value={currentOrder?.socks_color ? currentOrder.socks_color : ''}
                        onChange={(event) => {
                            setCurrentOrder({
                                ...currentOrder,
                                socks_color: event.target.value
                            });
                        }} /> : <>{order.socks_color ? order.socks_color : "N/A"} </>
                    }
                </div>
            </Grid>
            <Grid item xs={12} sm={6} className="text-left">
            <div className='form-group'>
                <Label>Número de cotización</Label>
                {viewMode ? 
                    <> {quotation_number ? quotation_number : "-"} </>
                    : 
                    !isValidador ? <TextField className='form-control' type="text" value={currentOrder?.quotation_number ? currentOrder.quotation_number : ''}
                    onChange={(event) => {
                        setCurrentOrder({
                            ...currentOrder,
                            quotation_number: event.target.value
                        });
                    }} /> : <>{quotation_number ? quotation_number : "-"} </>
                }
                </div>
            <div className='form-group'>
                <Label>¿Réplica?</Label>
                    {viewMode ? 
                        <> {order.replica ? order.replica : "N/A"} </>
                        : 
                        !isValidador ? <TextField className='form-control' type="text" value={currentOrder?.replica ? currentOrder.replica : ''}
                        onChange={(event) => {
                            setCurrentOrder({
                                ...currentOrder,
                                replica: event.target.value
                            });
                        }} /> : <>{order.replica ? order.replica : "N/A"} </>
                    }
                </div>
                <div className='form-group'>
                <Label>¿Es obsequio o garantía?</Label>
                    {viewMode ? 
                        <> {order.gift ? "Es un obsequio" : (order.warranty ? "Es una garantía" :"N/A" ) } </>
                        : !isValidador ? <ToggleButtonGroup
                            color="primary"
                            value={obsequioGarantia}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            >
                            {obsequioGarantiaMap ? Object.keys(obsequioGarantiaMap).map((key) => {
                                return <ToggleButton value={key} key={key}>{obsequioGarantiaMap[key]}</ToggleButton>
                            }) : null}
                        </ToggleButtonGroup> : <>{order.gift ? "Es un obsequio" : (order.warranty ? "Es una garantía" :"N/A" ) } </>
                    }
                </div>
                <div className='form-group'>
                <Label>Fecha de inicio de producción</Label>
                {viewMode ? 
                        <> {order.production_date_start ? order.production_date_start : "-"} </>
                        :
                        <TextField 
                            className='form-control' 
                            type="date" 
                            value={currentOrder?.production_date_start ? currentOrder.production_date_start : ''}
                            onChange={(event) => {
                                setCurrentOrder({
                                    ...currentOrder,
                                    production_date_start: event.target.value
                                });
                            }} 
                            disabled = {!(isAdministrador || isJefedeProduccion || isValidador )} 
                        />
                }
                </div>
                <div className='form-group'>
                <Label>Fecha de fin de producción</Label>
                {viewMode ? 
                        <> {order.production_date_end ? order.production_date_end : "-"} </>
                        :
                        <TextField 
                            className='form-control' 
                            type="date" 
                            value={currentOrder?.production_date_end ? currentOrder.production_date_end : ''}
                            onChange={(event) => {
                                setCurrentOrder({
                                    ...currentOrder,
                                    production_date_end: event.target.value
                                });
                            }} 
                            disabled = {!(isAdministrador || isJefedeProduccion || isValidador )} 
                        />
                }
                </div>
                <div className='form-group'>
                <Label>Fecha de entrega</Label>
                {viewMode ? 
                        <> {order.delivery_date ? order.delivery_date : "-"} </>
                        :
                        <TextField 
                            className='form-control' 
                            type="date" 
                            value={currentOrder?.delivery_date ? currentOrder.delivery_date : ''}
                            onChange={(event) => {
                                setCurrentOrder({
                                    ...currentOrder,
                                    delivery_date: event.target.value
                                });
                            }} 
                            disabled = {!(isAdministrador || isJefedeProduccion || isValidador )} 
                        />
                }
                </div>
                <div className='form-group'>
                    <Label>Boceto</Label>
                    {viewMode  ? 
                        <LoadImage order_id={order.id} editMode={editMode}/>                        
                    :
                    isAbleToEdit ?<> 
                        {editMode  ? 
                            <LoadImage order_id={order.id} editMode={editMode}/>    
                            : 
                            null
                        } 
                        <input type="file" className="form-control" name="image" id='image' onChange={(event) => {
                            setCurrentOrder({
                                ...currentOrder,
                                image: event.target.files ? event.target.files[0] : event.target.value
                            });
                        }
                        }
                        accept = "image/png, image/jpeg"
                        />
                        </> : <LoadImage order_id={order.id} editMode={editMode}/>
                    } 
                    {blobBoceto && !viewMode ? <><br/><b>Nuevo:</b> <br/><img src={URL.createObjectURL(blobBoceto)} alt="boceto" className='width-90' /></> : null}
                    
                </div>
            </Grid>
        </Grid> 
    </div>
    { viewMode? <Grid container spacing={2}>
        <Grid item xs={12} sm={12} className="text-right">
           <span className="text-log"><b>Última Modificación:</b> {new Date(order.updated_at).toLocaleString('es-CO')} - <b>Creado por:</b> {created_by.name ? created_by.name : created_by.username } </span>
        </Grid>
    </Grid>:null }

    <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className="text-right">
            {!viewMode ? 
            <Button className="btn btn-primary" onClick={handleSave}>Guardar</Button>   
            : isAbleToEdit ? <Button className="btn btn-primary" onClick={handleEditar}>Editar</Button> : null}
        </Grid>
    </Grid> 
    <br/>
    { viewMode ? <OrderProcessLog /> : null }
    </div>
}

export default CreateEditOrder;