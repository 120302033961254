import { useEffect, useState } from 'react';

import { useNavigate,  useParams } from "react-router-dom";

import { useAppContext } from '../../Context';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { setConsecutives, setConsecutive } from '../../slices/ordersSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPrint } from '@fortawesome/free-solid-svg-icons';

import { usePermissions, printConsecutiveTable, printConsecutiveTableBatch } from '../../services';

import DataTable from '../../components/shared/DataTable';
import { Button } from '../../components/shared/FormElements';

import { AttributesType, ColumnsType, ConsecutiveType,  } from '../../types';
import { setLoading } from '../../slices/miscSlice';

import { Grid } from "@mui/material";

import { constants } from "../../constants";

const ViewOrderConsecutivesProcess : React.FC = () => {
    const { apiCall } = useAppContext();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const consecutives = useAppSelector((state : any) => state.orders.consecutives);

    const hoyOnlyDate = new Date();
    hoyOnlyDate.setHours(0,0,0,0);

    const conditionDecorator = { 
        operator: '<', 
        value: hoyOnlyDate, 
        class_decorator : 'text-danger', 
        type:'date',
        limit : constants.settings.limitDateDays
    } 

     const initialColumns = [
        {title: 'No.', field: 'consecutive_number', cell_class : 'text-center'},
        {title: 'Cliente', field: 'order.customer.customer_name'},
        {title: 'Línea', field: 'line.line_name'},
        {title: 'Fecha de Órden', field: 'order.order_date', cell_class : 'text-center'},
        {title: 'Fecha de Consecutivo', field: 'created_at', type: 'datetime', cell_class : 'text-center'},
        {title: 'Fin de Producción', field: 'order.production_date_end', type: 'conditional_decorator', decorator: conditionDecorator, cell_class : 'text-center'},
        {title: 'Entrega', field: 'order.delivery_date', type: 'conditional_decorator', decorator: conditionDecorator, cell_class : 'text-center'},
        {title: 'Estado', field: 'order.status', type: 'decorator', decorator : { "En Proceso": "secondary", "Finalizado": "success", "Bloqueado": "warning", "Creada": "info", "Cancelada": "error" } , cell_class : 'text-center'}
    ];
    const [columns, setColumns] = useState<ColumnsType[]>(initialColumns);

    const { id } = useParams();

    const { canView_process } = usePermissions(['view_process']);

    const [enablePrint, setEnablePrint] = useState(false);

    const [selectedForPrint, setSelectedForPrint] = useState<Array<ConsecutiveType>>([]);

    useEffect(() => {

        if (!id) {
            navigate('/ordenes');
            return;
        }

        const getConsecutives = async () => {
            const response = await apiCall('GET', 'order/'+ id +'/consecutives/', null, '', 'Error al obtener los consecutivos');
            if (response) {
                dispatch(setConsecutives(response));
            }
        }
        
        getConsecutives();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
               
    if (!canView_process) {
        navigate('/')
        return null;
    }

    

    const handleViewConsecutive = (consecutive : ConsecutiveType) => {
        dispatch(setConsecutive(consecutive));
        navigate(`${consecutive.id}`);
    }

    const handlePrintConsecutive= async (consecutive : ConsecutiveType) => {
        let boceto = null;
        const resp = await apiCall('GET', `order/${id}/consecutive/${consecutive.id}/boceto/`, null, '', 'Error al obtener el boceto');
        if (resp?.image){
            boceto = resp.image;
        }
        dispatch(setLoading(true));
        await printConsecutiveTable(consecutive, boceto);
        dispatch(setLoading(false));
    }

    const actions : Array<any> = [
        canView_process ?  {icon: <FontAwesomeIcon icon={faEye} />, tooltip: 'Ver', onClick: (rowData :ConsecutiveType) => handleViewConsecutive(rowData)} : null,
        canView_process ? {icon: <FontAwesomeIcon icon={faPrint} />, tooltip: 'Imprimir', onClick: (rowData :any) => handlePrintConsecutive(rowData)} : null,
    ];

    const filterDate : Array<AttributesType> = [
        {value: 'order.order_date', name: 'Fecha de Órden'},
        {value: 'created_at', name: 'Fecha de Consecutivo'},
    ]

    const handlePrintSelecteAll = () => {
        setSelectedForPrint(consecutives);
    }

    const handlePrintSelecteNone = () => {
        setSelectedForPrint([]);
    }

    const handlePrintConsecutiveBatch = async () => {
        dispatch(setLoading(true));
        await printConsecutiveTableBatch(selectedForPrint);
        dispatch(setLoading(false));
    }

    const getBocetosForPrint = async () => {
        return new Promise((resolve, reject) => {
            const newConsecutives = consecutives.map(async (c : ConsecutiveType) => {
                let boceto :string = "";
                const resp = await apiCall('GET', `order/${id}/consecutive/${c.id}/boceto/`, null, '', 'Error al obtener el boceto');
                if (resp?.image){
                    boceto = resp.image;
                }
                return {...c, image_cache: boceto};
            } );

            Promise.all(newConsecutives).then((newConsecutives) => {
                // sort by consecutive number
                newConsecutives.sort((a : ConsecutiveType, b : ConsecutiveType) => a.consecutive_number - b.consecutive_number);
                dispatch(setConsecutives(newConsecutives));
                resolve(true);
            }).catch((error) => {
                console.log(error);
                reject(false);
            } );
        });

    }

    const handleSelectItem = (e:any, consecutive : ConsecutiveType) => {
        if (e.target.checked){
            setSelectedForPrint([...selectedForPrint, consecutive]);
        } else {
            setSelectedForPrint(selectedForPrint.filter((c : ConsecutiveType) => c.id !== consecutive.id));
        }
    }

    const handleEnablePrint = async (enable : boolean) => {

        await getBocetosForPrint();

        setEnablePrint(enable);

        const columnsToexport = [
            {title : 'Seleccionar', field: 'select', type: 'checkbox' },
            ...columns
        ]

        if (!enable) { 
            setSelectedForPrint([]);
            setColumns(initialColumns);
        }else {
            setColumns(columnsToexport);
        }

    }

    const handleGoBack = () => {
        navigate(-1);
    }
 
    return <div className="container-wrap">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} className="text-left">
                        <h2 className="text-left">Consecutivos de Orden No. {id}</h2>
                    </Grid>
                    
                    { !enablePrint ? <>
                        <Grid item xs={12} sm={6} className="text-right"></Grid>
                        <Grid item xs={12} sm={1} className="d-flex-center icon-btn">       
                            <FontAwesomeIcon icon={faPrint} size="2x" className="text-primary" onClick={()=>handleEnablePrint(true)} />
                        </Grid>
                        </> 
                        : 
                    <>
                        <Grid item xs={12} sm={1} className="text-center">
                            <Button className="btn btn-primary" onClick={()=>handleEnablePrint(false)}>Cancelar</Button>
                        </Grid>
                        <Grid item xs={12} sm={2} className="text-right">
                            {selectedForPrint.length < consecutives.length ? <Button className="btn btn-primary" onClick={handlePrintSelecteAll}>Seleccionar Todos</Button> : <Button className="btn btn-primary" onClick={handlePrintSelecteNone}>Deseleccionar Todos</Button> }
                        </Grid>
                        <Grid item xs={12} sm={2} className="text-right">
                            { selectedForPrint.length > 0 ?<Button className="btn btn-primary" onClick={handlePrintConsecutiveBatch}>Imprimir</Button>  : null }
                        </Grid>
                    </>
                    }
                    <Grid item xs={12} sm={2} className="text-right">
                    {canView_process && <Button className="btn btn-primary" onClick={handleGoBack}>Regresar a la orden</Button>}
                    </Grid>
                </Grid> 
                <DataTable data={consecutives} columns={columns} actions={actions}  filterByDateBy={filterDate} selectItem={handleSelectItem}  itemsSelected={selectedForPrint} />
            </div>;
}


export default ViewOrderConsecutivesProcess;