import { createContext, ReactNode, useContext, useState } from 'react';

// hooks redux
import { useAppDispatch } from './hooks';
import { setPopAlert, setLoading} from './slices/miscSlice';

// agent
import { post, get,put,del ,patch } from './agent';

// services
import { handleErrors } from './services';

// types
import { UserType } from './types';

type AppContextType = {
    apiCall: (method : MethodType, url:string, body: any, successMessage:string, errorMessage:string, options?:any) => Promise<any>;
    auth: AuthType;
    setAuth: (auth: AuthType) => void;
    logout: () => void;
    refreshCustomers: () => void;
    currentRoute: string;
    setCurrentRoute: (route: string) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
};

type AuthType = {
        token: string;
        user:  UserType;
        isLogged: boolean;
}

const Context = createContext<AppContextType | undefined>(undefined);

type ProviderProps = {
    children: ReactNode;
    };

    // define type method as 'POST', 'GET', 'PUT', 'DELETE'
type MethodType = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';

export const ContextProvider = ({ children }: ProviderProps) => {
    const dispatch = useAppDispatch();

    const initialAuth = {
        token: '',
        user: {
            id: 0,
            username: '',
            email: '',
            status: false,
            groups: [],
            permissions: []
        },
        isLogged: false
    }

    const [auth, setAuth] = useState<AuthType>(initialAuth);

    const [open, setOpen] = useState<boolean>(true);

    const [currentRoute, setCurrentRoute] = useState<string>('');

    const apiCall = async (method : MethodType, url:string,  body: any, successMessage:string, errorMessage:string, options?:any)  => {
        dispatch(setPopAlert({message: '', show: false, alertType: ''}));
        dispatch(setLoading(true));
        try{
            const methodMap: Record<MethodType, (url: string, body: any, options?:any) => Promise<any>> = {
                'GET': get,
                'POST': post,
                'PUT': put,
                'DELETE': del,
                'PATCH': patch
                };
            
            const selectedMethod = methodMap[method];
            if (!selectedMethod) {
                dispatch(setLoading(false));
                dispatch(setPopAlert({message: 'Method not allowed', show: true, alertType: 'danger'}));
                return null;
            }

            const rawResp =  await selectedMethod(url, body, options);
            
            const resp = await rawResp.json();
           
            
            if (rawResp.status === 200 || rawResp.status === 201) {
                dispatch(setLoading(false));
                if (successMessage.length > 0) dispatch(setPopAlert({message: successMessage, show: true, alertType: 'success'}));
                return resp;
            } 
            dispatch(setLoading(false));
            const errorMessageText = resp? resp.detail : (errorMessage ? errorMessage : 'Error desconocido');
            dispatch(setPopAlert({message: errorMessageText, show: true, alertType: 'danger'}));
            handleErrors(rawResp);

        } catch (error : any) {

            dispatch(setLoading(false));
           let errorMessageText = 'Error desconocido'; 
            if (error?.response?.message || error?.message || error?.response?.data?.message || error?.detail) {
                errorMessageText = error?.response?.message || error?.message || error?.response?.data?.message || error?.detail;
            }
            console.log ('error', errorMessageText);
            dispatch(setPopAlert({message: errorMessage.length > 0? errorMessage : errorMessageText, show: true, alertType: 'danger'}));
            return null;

        }
        return null;
    };

    const logout = () => {
        localStorage.clear();
        dispatch(setLoading(true));
        window.location.reload();
    }

    const refreshCustomers = async () => {
        const resp = await apiCall('GET', 'customers/refresh/',null, '', 'Error al obtener los clientes'); 
          if (resp){
            console.log (resp);
          }
    }

    const props = {
        apiCall,
        auth,
        setAuth,
        logout,
        refreshCustomers,
        currentRoute,
        setCurrentRoute,
        open,
        setOpen
    };

    return <Context.Provider value={props}>{children}</Context.Provider>;

};

export const useAppContext = () => {
    const context = useContext(Context);

    if (context === undefined) {
        throw new Error('useAppContext must be used within a ContextProvider');
    }

    return context;
}